import { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { CustomFilter } from '../../../components/CustomFilter';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { FilterBadge } from '../../../models/FilterBadge';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { initialFilterLowLevelProduct } from '../utils/utils';

interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function LowLevelProductFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  // Filter States
  const [reset, setReset] = useState<boolean>(false);
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : null,
    locationId: queryParams.locationId ? queryParams.locationId : null,
  });
  const [lastXDays, setLastXDays] = useState<number>(
    queryParams.lastXDays ? queryParams.lastXDays : 90
  );
  const lastXDaysRef = useRef<any>(null);

  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Get initial values from the url
  useEffect(() => {
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.lastXDays = lastXDays;
    setQueryParams(newQueryParams);
  };
  useEffect(() => {
    if (reset) {
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
        lastXDays: 90,
      };
      setLastXDays(90);
      setQueryParams({ ...initialFilterLowLevelProduct, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const onLastXDaysChange = (e: any) => {
    if (e.target.value) {
      setLastXDays(e.target.value);
    } else {
      setLastXDays(90);
    }
  };

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );

  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (!!queryParams.lastXDays) {
      setLastXDays(queryParams.lastXDays);
      updateBadge('lastXDays', 'Last ' + lastXDays + ' days', lastXDaysRef);
    } else {
      clearFilters.push('lastXDays');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }

    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      if (ref.current?.type === 'date') {
        ref.current.value = null;
      } else {
        ref.current?.clearValue();
      }
      let params = { ...queryParams };
      params[keyParam] = undefined;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
              canBeRemoved={item.key === 'lastXDays' ? false : true}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter applyFilters={applyFilter} resetFilters={resetFilters}>
        <Fragment>
          <FilterCascadeEntitySelections
            setReset={setReset}
            reset={reset}
            entityFilters={entityFilters}
            setEntityFilters={setEntityFilters}
            level={2}
            setEntityListBadge={setEntityFilteBadges}
            entityFilterBadges={entityFilterBadges}
          />
          <div className='mb-4'>
            <label className='form-label fw-bold'>Last X Days</label>
            <input
              ref={lastXDaysRef}
              autoComplete='none'
              type='number'
              className='form-control'
              value={lastXDays}
              placeholder='Days'
              onChange={onLastXDaysChange}
            />
          </div>
        </Fragment>
      </CustomFilter>
    </>
  );
}
