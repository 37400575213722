import { Fragment, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Formik, Form, FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { CreateOrdersSchema } from '../utils/createOrdersSchema';
import { OrderCreateModel } from '../models/OrderCreateModel';
import { PlaceOrderDetails } from './PlaceOrderDetails';
import { PlaceOrderResume } from './PlaceOrderResume';
import orderServices from '../services/OrdersServices';
import { ErrorObject } from '../../../utils/handle400Error';
import { PlaceOrderSuccessModal } from './PlaceOrderSuccessModal';
import { PlaceOrderErrorsModal } from './PlaceOrderErrorsModal';
import { useHistory } from 'react-router-dom';

interface Props {
  order: OrderCreateModel;
  setProductsAdded: any;
  setOrder: any;
}
const handleOrdersErrors = (backendErrors: ErrorObject) => {
  let errors = [];
  for (let key in backendErrors) {
    errors.push(backendErrors[key]);
  }
  return errors.join(',');
};
export function PlaceOrder({ order, setProductsAdded, setOrder }: Props) {
  const intl = useIntl();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<number>(0);
  const [errors, setErrors] = useState<string>('');
  const history = useHistory();

  const saveOrder = (
    values: OrderCreateModel,
    actions: FormikHelpers<OrderCreateModel>
  ) => {
    orderServices
      .createOrder(values)
      .then((response) => {
        setShowSuccessModal(true);
        setOrderNumber(response.data.id);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          setErrors(handleOrdersErrors(error.response.data.errors));
          setShowErrorModal(true);
        }
      });
  };
  const onClose = () => {
    history.push('/orders/list');
  };
  const onCloseErrorsModal = () => {
    setErrors('');
    setShowErrorModal(false);
  };
  return (
    <Fragment>
      {showSuccessModal && (
        <PlaceOrderSuccessModal
          onClose={onClose}
          show={showSuccessModal}
          orderNumber={orderNumber}
        />
      )}
      {showErrorModal && (
        <PlaceOrderErrorsModal
          show={showErrorModal}
          onClose={onCloseErrorsModal}
          errors={errors}
        />
      )}

      <Formik
        enableReinitialize
        initialValues={order}
        validationSchema={CreateOrdersSchema(intl)}
        onSubmit={saveOrder}
      >
        {({ values }) => (
          <Form>
            <Fragment>
              <div>
                <div
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/patterns/myOrder.png'
                    )})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right bottom',
                    backgroundColor: '#42BB85',
                  }}
                  className='card-body p-0 h-150px'
                >
                  <h3 className='fw-bolder text-white text-left p-10'>
                    My Order
                  </h3>
                </div>
              </div>
              <PlaceOrderDetails
                order={order}
                setProductsAdded={setProductsAdded}
                setOrder={setOrder}
              />
              <div className='card p-8 mt-10'>
                <PlaceOrderResume setOrder={setOrder} />
                <div className='mb-0 row'>
                  <button
                    type='submit'
                    className='btn btn-success'
                    id='kt_subscriptions_create_button'
                    disabled={values.details.length > 0 ? false : true}
                  >
                    <span className='indicator-label'>Place Order</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                </div>
              </div>
            </Fragment>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
