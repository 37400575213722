import axios from 'axios';
import { PagedResult } from '../../../models/pagedResult';
import {
  LocationAddress,
  LocationModel,
} from '../../entities/models/LocationModel';
import { LOCATIONS_URL } from '../../entities/services/LocationsServices';
import { AvailabilityInfoModel } from '../../products/models/AvailabilityInfoModel';
import { productsAndKitsHelpers } from '../helpers/productAndKits';
import { OrderCreateModel } from '../models/OrderCreateModel';
import { OrderViewModel } from '../models/OrderViewModel';
import { ProductOrderModel } from '../models/ProductOrderModel';

export const ORDER_URL = `/api/v1/orders`;

const orderServices = {
  findOrders: function (queryParams: any) {
    const params = {
      ...queryParams,
      page: queryParams.pageNumber,
    };
    return axios.get<PagedResult>(ORDER_URL, { params: params });
  },
  getOrder: (id: number) => {
    return axios.get<OrderViewModel>(`${ORDER_URL}/${id}`);
  },
  getAvailabilitiesInfo: () => {
    return axios.get<AvailabilityInfoModel>(`${ORDER_URL}/availabilities/info`);
  },
  findProducts: (locationId: number, filter: string, orderBy: string) => {
    const params = {
      locationId: locationId,
      filter: filter,
      orderBy: orderBy,
    };
    return axios.get<ProductOrderModel[]>(
      `${ORDER_URL}/get-order-detail-info/${locationId}`,
      { params: params }
    );
  },
  getProductsAndKitsForThirdParty: (filter: string, orderBy: string) => {
    const params = {
      filter: filter,
      orderBy: orderBy,
    };
    return axios.get<ProductOrderModel[]>(
      `${ORDER_URL}/get-third-party-order-detail-info`,
      { params: params }
    );
  },
  createOrder: (order: OrderCreateModel) => {
    const body = productsAndKitsHelpers.processRequest(order);
    return axios.post(`${ORDER_URL}`, body);
  },
  closeOrder: (orderId: number) => {
    return axios.put(`${ORDER_URL}/close/${orderId}`);
  },
  cancelOrder: (
    orderId: number,
    extraParams: { warehouseInventoryAction?: 'return' | 'notReturn' }
  ) => {
    return axios.put(`${ORDER_URL}/cancel/${orderId}`, {
      orderId: orderId,
      warehouseInventoryAction: extraParams.warehouseInventoryAction,
    });
  },
  returnOrder: (
    orderId: number,
    extraParams: { warehouseInventoryAction?: 'return' | 'notReturn' }
  ) => {
    return axios.put(`${ORDER_URL}/return/${orderId}`, {
      orderId: orderId,
      warehouseInventoryAction: extraParams.warehouseInventoryAction,
    });
  },
  canCreateThirdPartyOrders: () => {
    return axios.get<boolean>(`${ORDER_URL}/can-create-third-party-orders`);
  },
  createThirdPartyLocation: (address: LocationAddress) => {
    return axios.post<LocationModel>(`${LOCATIONS_URL}/third-party`, address);
  },
  processOrder: (orderId: number) => {
    return axios.put(`${ORDER_URL}/process/${orderId}`);
  },
  packOrder: (orderId: number, trackingNumber: string) => {
    return axios.put(`${ORDER_URL}/pack/${orderId}`, { trackingNumber });
  },
  shipOrder: (orderId: number, trackingNumber: string) => {
    return axios.put(`${ORDER_URL}/ship/${orderId}`, { trackingNumber });
  },
  updateTrackingNumber: (orderId: number, trackingNumber: string) => {
    return axios.put(`${ORDER_URL}/${orderId}/tracking-number`, {
      trackingNumber,
    });
  },
  printOrders: (
    ordersId: number[],
    printAll: boolean,
    processBeforePrint: boolean,
    queryParams: any
  ) => {
    return axios.post(
      `${ORDER_URL}/print`,
      {
        ids: ordersId,
        filter: queryParams,
        printAll: printAll,
        processBeforePrint: processBeforePrint,
      },
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      }
    );
  },
};

export default orderServices;
