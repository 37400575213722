import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { CustomInput } from '../../../components/CustomInput';
import { ProductModel } from '../models/ProductModel';

export function ProductCardAttributesStep2() {
  const { values, setFieldValue } = useFormikContext<ProductModel>();

  useEffect(() => {
    if (!values.cardAttributes?.isBaseStock) {
      setFieldValue('cardAttributes.baseProductId', 0);
    }
    if (values.cardAttributes.valueType === 'Fixed') {
      setFieldValue('cardAttributes.minValue', 0);
      setFieldValue('cardAttributes.maxValue', 0);
    }
    if (values.cardAttributes.valueType === 'Range') {
      setFieldValue('cardAttributes.value', 0);
    }
    if (!values.cardAttributes.canActivate) {
      setFieldValue('cardAttributes.minValue', 0);
      setFieldValue('cardAttributes.maxValue', 0);
      setFieldValue('cardAttributes.value', 0);
    }
  }, [setFieldValue, values]);

  const handleChangeManufacturer = (e: any) => {
    setFieldValue('cardAttributes.mfDataRequired', e.target.checked);

    if (e.target.checked) {
      setFieldValue('cardAttributes.canActivate', true);
    }
  };
  return (
    <div className='w-100'>
      <div className='row'>
        <div className='fw-bolder text-gray-800 fs-2 mb-10'>
          Card Attributes
        </div>
      </div>

      <div className='fv-row'>
        <div className='d-flex flex-stack'>
          <div className='me-5'>
            <label className='fs-5 fw-bold'>Value</label>

            <div className='fs-7 fw-bold text-muted'>
              Select wether the card has fixed value or allows a range
            </div>
          </div>

          <label className='form-check form-check-custom form-check-solid'>
            <Field
              className={`form-check-input ${
                values.cardAttributes.valueType === 'Fixed' ? 'bg-primary' : ''
              }`}
              id='cardAttributes.valueType'
              name='cardAttributes.valueType'
              type='radio'
              value='Fixed'
            />
            <span className='form-check-label fw-bold text-muted'>Fixed</span>
          </label>

          <label className='form-check form-check-custom form-check-solid'>
            <Field
              className={`form-check-input ${
                values.cardAttributes.valueType === 'Range' ? 'bg-primary' : ''
              }`}
              name='cardAttributes.valueType'
              id='cardAttributes.valueType'
              type='radio'
              value='Range'
            />
            <span className='form-check-label fw-bold text-muted'>Range</span>
          </label>
        </div>
        <div className='fv-row mt-8'>
          <div className='d-flex flex-stack'>
            <div className='me-5'>
              <label className='form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20 fw-bolder'>
                Can Activate
              </label>
            </div>

            <label className='form-check form-switch form-check-custom form-check-solid'>
              <Field
                className={`form-check-input me-1 ${
                  values.cardAttributes.canActivate ? 'bg-primary' : ''
                }`}
                name='cardAttributes.canActivate'
                type='checkbox'
              />

              <span className='form-check-label fw-bold text-muted'>
                {values.cardAttributes.canActivate ? 'Yes' : 'No'}
              </span>
            </label>
          </div>
        </div>

        {values.cardAttributes.valueType === 'Fixed' && (
          <div className='fv-row mt-8'>
            <div className='col-6'>
              <div className='input-group  mb-5 '>
                <span className='input-group-text'>$</span>
                <CustomInput
                  id='cardAttributes.value'
                  name='cardAttributes.value'
                  type='number'
                  isDisabled={!values.cardAttributes.canActivate}
                />
              </div>
            </div>
          </div>
        )}
        {values.cardAttributes.valueType === 'Range' && (
          <div className='fv-row mt-8'>
            <div className='col-6'>
              <label className={`form-label`}>Min</label>
              <div className='input-group  mb-5 '>
                <span className='input-group-text'>$</span>
                <CustomInput
                  key='cardAttributes.minValue'
                  name='cardAttributes.minValue'
                  type='number'
                  isDisabled={!values.cardAttributes.canActivate}
                />
              </div>
            </div>
            <div className='col-6'>
              <label className={`form-label`}>Max</label>
              <div className='input-group  mb-5 '>
                <span className='input-group-text'>$</span>
                <CustomInput
                  key='cardAttributes.maxValue'
                  name='cardAttributes.maxValue'
                  type='number'
                  isDisabled={!values.cardAttributes.canActivate}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row'>
        <div className='col-12'>
          <CustomInput
            label='Masking Format'
            placeholder='Masking Format'
            id='cardCategory.maskingFormat'
            name='cardAttributes.maskingFormat'
            isRequired={true}
          />
        </div>
      </div>

      <div className='fv-row mt-8'>
        <div className='d-flex flex-stack'>
          <div className='me-5'>
            <label className='form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20 fw-bolder'>
              Manufacturer Data Required
            </label>
          </div>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              className={`form-check-input me-1 ${
                values.cardAttributes.mfDataRequired ? 'bg-primary' : ''
              }`}
              name='cardAttributes.mfDataRequired'
              type='checkbox'
              onChange={(e: any) => handleChangeManufacturer(e)}
            />

            <span className='form-check-label fw-bold text-muted'>
              {values.cardAttributes.mfDataRequired ? 'Yes' : 'No'}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}
