import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect from 'react-select';
import { entityServices } from '../modules/users/services/EntityServices';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../app/redux';
import { UserGroupRelationModel } from '../modules/auth/models/UserGroupRelationModel';
import { EntityFiltersModel } from '../models/EntityFiltersModel';
import { FilterBadge } from '../models/FilterBadge';
export interface ProductValueLabelPair {
  value: number;
  label: string;
}

const keyLabel = 'cascadeselects';
interface PropsFilter {
  entityFilters: EntityFiltersModel;
  setEntityFilters: any;
  reset: any;
  setReset: any;
  level?: number;
  updateBadge?: any;
  setEntityListBadge?: any;
  entityFilterBadges?: any;
}
export function FilterCascadeEntitySelections({
  entityFilters,
  setEntityFilters,
  reset,
  setReset,
  level = 4,
  updateBadge,
  setEntityListBadge,
  entityFilterBadges,
}: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [accounts, setAccounts] = useState<ProductValueLabelPair[]>([]);
  const [clients, setClients] = useState<ProductValueLabelPair[]>([]);
  const [franchises, setFranchises] = useState<ProductValueLabelPair[]>([]);
  const [locations, setLocations] = useState<ProductValueLabelPair[]>([]);

  //Clear refs
  const accountRef = useRef<null | Select>(null);
  const clientsRef = useRef<null | Select>(null);
  const franchiseRef = useRef<null | Select>(null);
  const locationsRef = useRef<null | Select>(null);
  // Disable Flags
  const [d1, setD1] = useState(true);
  const [d2, setD2] = useState(true);
  const [d3, setD3] = useState(true);

  // Get initial values from the url
  const accountsList = useCallback(() => {
    entityServices.getAccounts().then((values) => {
      const body = values.data;
      const items: ProductValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.accountName };
            })
          : [];
      setAccounts(items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const clientList = useCallback((accountId: number) => {
    entityServices.getClients(accountId).then((values) => {
      const body = values.data;
      const items: ProductValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setClients(items);
      setD1(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const franchiseList = useCallback((clientId: number) => {
    entityServices.getFranchises(clientId).then((values) => {
      const body = values.data;
      const items: ProductValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setFranchises(items);
      setD2(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const locationsList = useCallback(
    (franchiseId: number) => {
      entityServices.getLocations(franchiseId).then((values) => {
        const body = values.data;
        const items: ProductValueLabelPair[] =
          body.length > 0
            ? body.map((x) => {
                return { value: x.id, label: x.name };
              })
            : [];
        setLocations(items);
        setD3(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleAccountChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: option.value,
      }));

      clientList(option.value);
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: undefined,
      }));
    }
    updateEntityListBadge(option, 'accountId', accountRef);
    // Clear Refs 2 .. N
    clientsRef.current?.clearValue();
    franchiseRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD1(true);
    setD2(true);
    setD3(true);
  };
  const handleClientChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: option.value,
      }));
      franchiseList(option.value);
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: undefined,
      }));
    }
    if (level > 1) updateEntityListBadge(option, 'clientId', clientsRef);
    // Clear Refs 3 .. N
    franchiseRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD2(true);
    setD3(true);
  };
  const handleFranchiseChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: option.value,
      }));
      locationsList(option.value);
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: undefined,
      }));
    }
    if (level > 2) updateEntityListBadge(option, 'franchiseId', franchiseRef);
    // Clear Refs 4 .. N
    locationsRef.current?.clearValue();
    setD3(true);
  };
  const handleLocationChange = (option: any) => {
    if (option) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: option.value,
      }));
    } else {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        locationId: undefined,
      }));
    }
    if (level > 3) updateEntityListBadge(option, 'locationId', locationsRef);
  };

  const updateEntityListBadge = (option: any, key: string, ref: any) => {
    if (!setEntityListBadge || !entityFilterBadges) {
      return;
    }
    if (option) {
      const badges = [...entityFilterBadges];
      const finded = entityFilterBadges?.find(
        (item: FilterBadge) => item.key === key
      );
      if (finded && finded !== undefined) {
        badges.splice(badges.indexOf(finded), 1, {
          key: key,
          value: option.label,
          ref: ref,
        });
      } else {
        badges.push({
          key: key,
          value: option.label,
          ref: ref,
        });
      }
      setEntityListBadge(badges);
    } else {
      setEntityListBadge((s: FilterBadge[]) => s.filter((x) => x.key !== key));
    }
  };
  useEffect(() => {
    if (userGroupRelations.accountId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        accountId: userGroupRelations.accountId,
      }));
    } else {
      if (userGroupRelations.accountId === null) {
        accountsList();
      }
    }

    if (userGroupRelations.clientId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        clientId: userGroupRelations.clientId,
      }));
    } else {
      const initAccount =
        userGroupRelations.accountId || entityFilters.accountId;
      if (initAccount) {
        clientList(initAccount);
      }
    }

    if (userGroupRelations.franchiseId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: userGroupRelations.franchiseId,
      }));
    } else {
      const initClientId =
        userGroupRelations.clientId || entityFilters.clientId;
      if (initClientId) {
        franchiseList(initClientId);
      }
    }

    if (userGroupRelations.locationId) {
      setEntityFilters((s: EntityFiltersModel) => ({
        ...s,
        franchiseId: userGroupRelations.locationId,
      }));
    } else {
      const initFranchiseId =
        userGroupRelations.franchiseId || entityFilters.franchiseId;
      if (initFranchiseId) {
        locationsList(initFranchiseId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Auto select options:
  useEffect(() => {
    if (accounts.length === 1) handleAccountChange(accounts[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);
  useEffect(() => {
    if (clients.length === 1) handleClientChange(clients[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  useEffect(() => {
    if (franchises.length === 1) handleFranchiseChange(franchises[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchises]);
  useEffect(() => {
    if (locations.length === 1) handleLocationChange(locations[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  // Reset Filters
  useEffect(() => {
    if (reset && !userGroupRelations.accountId) {
      // Clear Refs 2 .. N
      accountRef.current?.clearValue();
      clientsRef.current?.clearValue();
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD1(true);
      setD2(true);
      setD3(true);
      return setReset(false);
    }
    if (reset && !userGroupRelations.clientId) {
      // Clear Refs 2 .. N
      clientsRef.current?.clearValue();
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD2(true);
      setD3(true);
      return setReset(false);
    }
    if (reset && !userGroupRelations.franchiseId) {
      // Clear Refs 2 .. N
      franchiseRef.current?.clearValue();
      locationsRef.current?.clearValue();
      setD3(true);
      return setReset(false);
    }
    if (reset && !userGroupRelations.locationId) {
      locationsRef.current?.clearValue();
      return setReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);
  return (
    <Fragment>
      <div className='row'>
        {!userGroupRelations.accountId && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Account</label>
            <ReactSelect
              placeholder='All'
              value={
                accounts[
                  accounts.findIndex((x) => x.value === entityFilters.accountId)
                ]
              }
              isClearable={!userGroupRelations.accountId ? true : false}
              name={`${keyLabel}.accountId`}
              id={`${keyLabel}.accountId`}
              key={`${keyLabel}.accountId`}
              options={accounts}
              onChange={handleAccountChange}
              ref={accountRef}
            />
          </div>
        )}
        {!userGroupRelations.clientId && level > 1 && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Client</label>
            <ReactSelect
              placeholder='All'
              isClearable={!userGroupRelations.clientId ? true : false}
              key={`${keyLabel}.clientId`}
              id={`${keyLabel}.clientId`}
              options={clients}
              name={`${keyLabel}.clientId`}
              ref={clientsRef}
              value={
                clients[
                  clients.findIndex((x) => x.value === entityFilters.clientId)
                ]
              }
              isDisabled={d1}
              onChange={handleClientChange}
            />
          </div>
        )}
        {!userGroupRelations.franchiseId && level > 2 && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Franchise</label>
            <ReactSelect
              placeholder='All'
              isClearable={!userGroupRelations.franchiseId ? true : false}
              id={`${keyLabel}.franchiseId`}
              key={`${keyLabel}.franchiseId`}
              options={franchises}
              name={`${keyLabel}.franchiseId`}
              ref={franchiseRef}
              value={
                franchises[
                  franchises.findIndex(
                    (x) => x.value === entityFilters.franchiseId
                  )
                ]
              }
              isDisabled={d2}
              onChange={handleFranchiseChange}
            />
          </div>
        )}
        {!userGroupRelations.locationId && level > 3 && (
          <div className='mb-2' onClick={(e) => e.stopPropagation()}>
            <label className='form-label'>Location</label>
            <ReactSelect
              placeholder='All'
              isClearable={!userGroupRelations.locationId ? true : false}
              id={`${keyLabel}.locationId`}
              name={`${keyLabel}.locationId`}
              key={`${keyLabel}.locationId`}
              options={locations}
              ref={locationsRef}
              value={
                locations[
                  locations.findIndex(
                    (x) => x.value === entityFilters.locationId
                  )
                ]
              }
              isDisabled={d3}
              onChange={handleLocationChange}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}
