import { Fragment, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../../components/CustomFilter';
import { RootState } from '../../../../redux';
import ReactSelect from 'react-select';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';
import { FilterCascadeEntitySelections } from '../../../../components/FilterCascadeEntitySelection';
import { EntityFiltersModel } from '../../../../models/EntityFiltersModel';
import { FilterBadge } from '../../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../../components/CustomBadgeFilter';

const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
const autoReplenishOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function LocationInventoryMasterFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  // Filter States
  const [isActive, setIsActive] = useState<boolean | null>();
  const isActiveRef = useRef<null | Select>(null);
  const [isAutoReplenish, setAutoReplenish] = useState<boolean | null>();
  const isAutoReplenishRef = useRef<null | Select>(null);
  const [productCode, setProductCode] = useState<string | null>();

  const [reset, setReset] = useState<boolean>(false);
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: null,
    locationId: null,
  });
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };
  const handleAutoReplenish = (params: any) => {
    if (params) {
      setAutoReplenish(params.value);
    } else {
      setAutoReplenish(undefined);
    }
  };
  const handleProductCode = (e: any) => {
    if (e.target.value) {
      setProductCode(e.target.value);
    } else {
      setProductCode(undefined);
    }
  };
  const resetFilters = () => {
    // Clear All Ref
    isAutoReplenishRef.current?.clearValue();
    // Update QueryParams: Review if
    setReset(true);
    // Update QueryParams: Review if
    const newQueryParams = { ...queryParams };
    newQueryParams.pageNumber = 1;
    newQueryParams.active = true;
    newQueryParams.autoReplenish = undefined;
    newQueryParams.productCode = undefined;
    setQueryParams(newQueryParams);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };

    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;

    newQueryParams.active = isActive;
    newQueryParams.pageNumber = 1;
    newQueryParams.autoReplenish = isAutoReplenish;
    newQueryParams.productCode = productCode;
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (reset) {
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setQueryParams({ ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const showEntitiesFilter = !userGroupRelations.locationId;

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];
    if (queryParams.active || !queryParams.active) {
      setIsActive(queryParams.active);
      if (queryParams.active === true || queryParams.active === false)
        updateBadge(
          'active',
          queryParams.active === true
            ? 'Active'
            : queryParams.active === false
            ? 'Inactive'
            : '',
          isActiveRef
        );
      else clearFilters.push('active');
    }

    if (queryParams.autoReplenish || !queryParams.autoReplenish) {
      setAutoReplenish(queryParams.autoReplenish);
      if (
        queryParams.autoReplenish === true ||
        queryParams.autoReplenish === false
      ) {
        updateBadge(
          'autoReplenish',
          queryParams.autoReplenish ? 'Auto Replenish' : 'No Auto Replenish',
          isAutoReplenishRef
        );
      } else {
        clearFilters.push('autoReplenish');
      }
    }
    if (
      queryParams.productCode !== null ||
      queryParams.productCode !== undefined
    ) {
      setProductCode(queryParams.productCode);
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }
    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = undefined;
      params['clientId'] = undefined;
      params['franchiseId'] = undefined;
      params['locationId'] = undefined;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      ref.current?.clearValue();
      let params = { ...queryParams };
      params[keyParam] = undefined;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */

  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}

      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Fragment>
            <div className='row'>
              <div className='col-md-6'>
                {' '}
                <FilterCascadeEntitySelections
                  setReset={setReset}
                  reset={reset}
                  entityFilters={entityFilters}
                  setEntityFilters={setEntityFilters}
                  level={4}
                  setEntityListBadge={setEntityFilteBadges}
                  entityFilterBadges={entityFilterBadges}
                />
              </div>
              <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
                <label className='form-label fw-bold'>Active</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='isActive'
                  id='isActive'
                  onChange={handleIsActive}
                  options={activeOptions}
                  value={
                    activeOptions
                      ? activeOptions.filter(
                          (x: any) => x.value === isActive
                        )[0]
                      : null
                  }
                  ref={isActiveRef}
                  defaultValue={activeOptions[0]}
                />
                <label className='form-label fw-bold my-2'>
                  Auto Replenish
                </label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='isAutoReplenish'
                  id='isAutoReplenish'
                  onChange={handleAutoReplenish}
                  options={autoReplenishOptions}
                  value={
                    autoReplenishOptions
                      ? autoReplenishOptions.filter(
                          (x: any) => x.value === isAutoReplenish
                        )[0]
                      : null
                  }
                  ref={isAutoReplenishRef}
                />
                <label className='form-label fw-bold my-2'>Product Code</label>
                <input
                  autoComplete='none'
                  type='text'
                  className='form-control form-control-sm form-control-solid'
                  onChange={(e) => handleProductCode(e)}
                />
              </div>
            </div>
          </Fragment>
        </div>
      </CustomFilter>
    </>
  );
}
