import { Fragment, useEffect, useRef } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CustomFilter } from '../../../components/CustomFilter';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { initialFilterStandardOrder } from '../utils/utils';
import ReactSelect from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';
import { FilterBadge } from '../../../models/FilterBadge';
import moment from 'moment';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';

interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function StandardOrdersFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  // Filter States
  const [reset, setReset] = useState<boolean>(false);

  // Filter Dates
  const [dateFrom, setDateFrom] = useState<string | null | undefined>(
    queryParams.dateFrom || initialFilterStandardOrder.dateFrom
  );
  const dateFromRef = useRef<any>(null);

  const [dateTo, setDateTo] = useState<string | null | undefined>(
    queryParams.dateTo || initialFilterStandardOrder.dateTo
  );
  const dateToRef = useRef<any>(null);

  const [filterDateBy, setFilterDateBy] = useState<string | null | undefined>(
    queryParams.filterDateBy || initialFilterStandardOrder.filterDateBy
  );

  const filterDateByRef = useRef<null | Select>(null);

  const filterOptions = [
    { value: 'orderDate', label: 'Order Date' },
    { value: 'shipDate', label: 'Ship Date' },
  ];
  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : null,
    locationId: queryParams.locationId ? queryParams.locationId : null,
  });
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );

  const handleDateFrom = (e: any) => {
    if (e.target.value) {
      setDateFrom(e.target.value);
    } else {
      setDateFrom(undefined);
    }
  };

  const handleDateTo = (e: any) => {
    if (e.target.value) {
      setDateTo(e.target.value);
    } else {
      setDateTo(undefined);
    }
  };

  const handleFilterDateBy = (params: any) => {
    if (params) {
      setFilterDateBy(params.value);
    } else {
      setFilterDateBy('orderDate');
    }
  };

  const resetFilters = () => {
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    // Order Date
    newQueryParams.dateFrom = dateFrom;
    newQueryParams.dateTo = dateTo;
    newQueryParams.filterDateBy = filterDateBy;
    setQueryParams(newQueryParams);
  };
  useEffect(() => {
    if (reset) {
      filterDateByRef.current?.clearValue();

      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };

      setDateFrom(initialFilterStandardOrder.dateFrom);
      setDateTo(initialFilterStandardOrder.dateTo);
      setFilterDateBy(initialFilterStandardOrder.filterDateBy);

      setQueryParams({ ...initialFilterStandardOrder, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const showEntitiesFilter = !userGroupRelations.locationId;

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (!!queryParams.filterDateBy) {
      setFilterDateBy(queryParams.filterDateBy);
    }

    if (!!queryParams.dateFrom) {
      setDateFrom(queryParams.dateFrom);
      updateBadge(
        'dateFrom',
        'From: ' + moment(queryParams.dateFrom).format('MM/DD/YYYY'),
        dateFromRef
      );
    } else {
      clearFilters.push('dateFrom');
    }

    if (!!queryParams.dateTo) {
      setDateTo(queryParams.dateTo);
      updateBadge(
        'dateTo',
        'To: ' + moment(queryParams.dateTo).format('MM/DD/YYYY'),
        dateToRef
      );
    } else {
      clearFilters.push('dateTo');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }

    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      if (ref.current?.type === 'date') {
        ref.current.value = null;
      } else {
        ref.current?.clearValue();
      }
      let params = { ...queryParams };
      params[keyParam] = undefined;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <Fragment>
          <div className='row'>
            <div className='col-md-6'>
              {' '}
              <FilterCascadeEntitySelections
                setReset={setReset}
                reset={reset}
                entityFilters={entityFilters}
                setEntityFilters={setEntityFilters}
                level={4}
                setEntityListBadge={setEntityFilteBadges}
                entityFilterBadges={entityFilterBadges}
              />
            </div>
            <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Filter Date By</label>
                <ReactSelect
                  maxMenuHeight={400}
                  // menuPortalTarget={document.body}
                  // styles={{ menuPortal: (base) => ({ ...base, zIndex: 106 }) }}
                  // menuPosition={'fixed'}
                  isClearable
                  name='filterDateBy'
                  id='filterDateBy'
                  onChange={handleFilterDateBy}
                  options={filterOptions}
                  value={
                    filterOptions
                      ? filterOptions.filter(
                          (x: any) => x.value === filterDateBy
                        )[0]
                      : null
                  }
                  ref={filterDateByRef}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Date From</label>
                <input
                  ref={dateFromRef}
                  autoComplete='none'
                  type='date'
                  className='form-control form-control-solid ps-15'
                  onChange={(e) => handleDateFrom(e)}
                  defaultValue={dateFrom ? dateFrom : ''}
                />
                <label className='form-label fw-bold'>Date To</label>
                <input
                  ref={dateToRef}
                  autoComplete='none'
                  type='date'
                  className='form-control form-control-solid ps-15'
                  onChange={(e) => handleDateTo(e)}
                  defaultValue={dateTo ? dateTo : ''}
                />
              </div>
            </div>
          </div>
        </Fragment>
      </CustomFilter>
    </>
  );
}
