import { Fragment, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { initialFilterOrders } from '../utils/utils';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { FilterBadge } from '../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';
import moment from 'moment';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';

const originOptions = [
  { value: 'Web', label: 'Web' },
  { value: 'Auto', label: 'Auto' },
  { value: 'Phone', label: 'Phone' },
  { value: 'Email', label: 'Email' },
  { value: 'ForceOut', label: 'Force Out' },
  { value: 'ThirdParty', label: 'Third Party' },
];
const statusOptions = [
  { value: 'New', label: 'New' },
  { value: 'BackOrdered', label: 'Backordered' },
  { value: 'Picking', label: 'Picking' },
  { value: 'Packed', label: 'Packed' },
  { value: 'Shipped', label: 'Shipped' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Returned', label: 'Returned' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}
export function OrdersFilter({ setQueryParams, queryParams }: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Filter States
  const [origin, setOrigin] = useState<'' | null>();
  const [status, setStatus] = useState<'' | null>();
  const [reset, setReset] = useState<boolean>(false);
  const originRef = useRef<null | Select>(null);
  const statusRef = useRef<null | Select>(null);
  // Filter States
  const [orderDateFrom, setOrderDateFrom] = useState<string | null>();
  const orderDateFromRef = useRef<any>(null);

  const [orderDateTo, setOrderDateTo] = useState<string | null>();
  const orderDateToRef = useRef<any>(null);

  const [shipDateFrom, setShipDateFrom] = useState<string | null>();
  const shipDateFromRef = useRef<any>(null);

  const [shipDateTo, setShipDateTo] = useState<string | null>();
  const shipDateToRef = useRef<any>(null);

  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : undefined,
    clientId: queryParams.clientId ? queryParams.clientId : undefined,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : undefined,
    locationId: queryParams.locationId ? queryParams.locationId : undefined,
  });
  // Get initial values from the url
  useEffect(() => {
    if (!!queryParams.origin) {
      setOrigin(queryParams.origin);
    }
    if (!!queryParams.status) {
      setStatus(queryParams.status);
    }
    if (!!queryParams.orderDateFrom) {
      setOrderDateFrom(queryParams.orderDateFrom);
    }
    if (!!queryParams.orderDateTo) {
      setOrderDateTo(queryParams.orderDateTo);
    }
    if (!!queryParams.orderShipFrom) {
      setShipDateFrom(queryParams.orderShipFrom);
    }
    if (!!queryParams.orderShipTo) {
      setShipDateTo(queryParams.orderShipTo);
    }

    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };

    setEntityFilters(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const handleOrigin = (params: any) => {
    if (params) {
      setOrigin(params.value);
    } else {
      setOrigin(undefined);
    }
  };
  const handleStatus = (params: any) => {
    if (params) {
      setStatus(params.value);
    } else {
      setStatus(undefined);
    }
  };
  const resetFilters = () => {
    setReset(true);
  };
  const handleOrderDateFrom = (e: any) => {
    if (e.target.value) {
      setOrderDateFrom(e.target.value);
    } else {
      setOrderDateFrom(undefined);
    }
  };

  const handleOrderDateTo = (e: any) => {
    if (e.target.value) {
      setOrderDateTo(e.target.value);
    } else {
      setOrderDateTo(undefined);
    }
  };
  const handleShipDateFrom = (e: any) => {
    if (e.target.value) {
      setShipDateFrom(e.target.value);
    } else {
      setShipDateFrom(undefined);
    }
  };

  const handleShipDateTo = (e: any) => {
    if (e.target.value) {
      setShipDateTo(e.target.value);
    } else {
      setShipDateTo(undefined);
    }
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.origin = origin;
    newQueryParams.status = status;
    newQueryParams.pageNumber = 1;
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    // Order Date
    newQueryParams.orderDateFrom = orderDateFrom;
    newQueryParams.orderDateTo = orderDateTo;
    // Ship Date
    newQueryParams.orderShipFrom = shipDateFrom;
    newQueryParams.orderShipTo = shipDateTo;
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (reset) {
      originRef.current?.clearValue();
      statusRef.current?.clearValue();
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
        locationId: userGroupRelations.locationId
          ? userGroupRelations.locationId
          : undefined,
      };
      setOrderDateTo(undefined);
      setOrderDateFrom(undefined);
      setShipDateFrom(undefined);
      setShipDateTo(undefined);
      setQueryParams({ ...initialFilterOrders, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const showEntitiesFilter = !userGroupRelations.locationId;
  const OriginFilter = () => (
    <div className='mb-2' onClick={(e) => e.stopPropagation()}>
      <label className='form-label fw-bold'>Origin</label>
      <ReactSelect
        placeholder={<FormattedMessage id='COMMON.ALL' />}
        isClearable
        name='origin'
        id='origin'
        onChange={handleOrigin}
        options={originOptions}
        value={
          originOptions
            ? originOptions.filter((x: any) => x.value === origin)[0]
            : null
        }
        ref={originRef}
      />
    </div>
  );

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );

  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (!!queryParams.origin) {
      setOrigin(queryParams.origin);
      updateBadge('origin', queryParams.origin, originRef);
    } else {
      clearFilters.push('origin');
    }

    if (!!queryParams.status) {
      setStatus(queryParams.status);
      updateBadge('status', queryParams.status, statusRef);
    } else {
      clearFilters.push('status');
    }

    if (!!queryParams.orderDateFrom) {
      setOrderDateFrom(queryParams.orderDateFrom);
      updateBadge(
        'orderDateFrom',
        'From: ' + moment(queryParams.orderDateFrom).format('MM/DD/YYYY'),
        orderDateFromRef
      );
    } else {
      clearFilters.push('orderDateFrom');
    }

    if (!!queryParams.orderDateTo) {
      setOrderDateTo(queryParams.orderDateTo);
      updateBadge(
        'orderDateTo',
        'To: ' + moment(queryParams.orderDateTo).format('MM/DD/YYYY'),
        orderDateToRef
      );
    } else {
      clearFilters.push('orderDateTo');
    }
    if (!!queryParams.orderShipFrom) {
      setShipDateFrom(queryParams.orderShipFrom);
      updateBadge(
        'orderShipFrom',
        'Ship From: ' + moment(queryParams.orderShipFrom).format('MM/DD/YYYY'),
        shipDateFromRef
      );
    } else {
      clearFilters.push('orderShipFrom');
    }
    if (!!queryParams.orderShipTo) {
      setShipDateTo(queryParams.orderShipTo);
      updateBadge(
        'orderShipTo',
        'Ship To: ' + moment(queryParams.orderShipTo).format('MM/DD/YYYY'),
        shipDateToRef
      );
    } else {
      clearFilters.push('orderShipTo');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }
    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }

    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = undefined;
      params['clientId'] = undefined;
      params['franchiseId'] = undefined;
      params['locationId'] = undefined;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      if (ref.current?.type === 'date') {
        ref.current.value = null;
      } else {
        ref.current?.clearValue();
      }
      let params = { ...queryParams };
      params[keyParam] = undefined;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}

      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <Fragment>
          <div className='row'>
            <div className='col-md-6'>
              <FilterCascadeEntitySelections
                setReset={setReset}
                reset={reset}
                entityFilters={entityFilters}
                setEntityFilters={setEntityFilters}
                setEntityListBadge={setEntityFilteBadges}
                entityFilterBadges={entityFilterBadges}
              />
              {showEntitiesFilter && <OriginFilter></OriginFilter>}
            </div>
            <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
              {!showEntitiesFilter && <OriginFilter></OriginFilter>}

              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Status</label>
                <ReactSelect
                  maxMenuHeight={400}
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  // menuPortalTarget={document.body}
                  // styles={{ menuPortal: (base) => ({ ...base, zIndex: 106 }) }}
                  // menuPosition={'fixed'}
                  isClearable
                  name='status'
                  id='status'
                  onChange={handleStatus}
                  options={statusOptions}
                  value={
                    statusOptions
                      ? statusOptions.filter((x: any) => x.value === status)[0]
                      : null
                  }
                  ref={statusRef}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Date From</label>
                <input
                  ref={orderDateFromRef}
                  autoComplete='none'
                  type='date'
                  className='form-control form-control-solid ps-15 '
                  onChange={(e) => handleOrderDateFrom(e)}
                  defaultValue={orderDateFrom ? orderDateFrom : ''}
                />
                <label className='form-label fw-bold'>Date To</label>
                <input
                  ref={orderDateToRef}
                  autoComplete='none'
                  type='date'
                  className='form-control form-control-solid ps-15 '
                  onChange={(e) => handleOrderDateTo(e)}
                  defaultValue={orderDateTo ? orderDateTo : ''}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Ship Date From</label>
                <input
                  ref={shipDateFromRef}
                  autoComplete='none'
                  type='date'
                  className='form-control form-control-solid ps-15 '
                  onChange={(e) => handleShipDateFrom(e)}
                  defaultValue={shipDateFrom ? shipDateFrom : ''}
                />
                <label className='form-label fw-bold'>Ship Date To</label>
                <input
                  ref={shipDateToRef}
                  autoComplete='none'
                  type='date'
                  className='form-control form-control-solid ps-15 '
                  onChange={(e) => handleShipDateTo(e)}
                  defaultValue={shipDateTo ? shipDateTo : ''}
                />
              </div>
            </div>
          </div>
        </Fragment>
      </CustomFilter>
    </>
  );
}
