import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { initialFilterFulfillment } from '../utils/utils';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { shallowEqual, useSelector } from 'react-redux';
import { selectStyles } from '../../../components/CustomSelect';
import { EntityFiltersModel } from '../../../models/EntityFiltersModel';
import { FilterCascadeEntitySelections } from '../../../components/FilterCascadeEntitySelection';
import { FilterBadge } from '../../../models/FilterBadge';
import { CustomBadgeFilter } from '../../../components/CustomBadgeFilter';

const binaryOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const statusOptions = [
  { value: 'New', label: 'New' },
  { value: 'Picking', label: 'Picking' },
  { value: 'BackOrdered', label: 'Backordered' },
  { value: 'Packed', label: 'Packed' },
  { value: 'Shipped', label: 'Shipped' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Returned', label: 'Returned' },
];

const originOptions = [
  { value: 'Web', label: 'Web' },
  { value: 'Auto', label: 'Auto' },
  { value: 'Phone', label: 'Phone' },
  { value: 'Email', label: 'Email' },
  { value: 'ForceOut', label: 'Force Out' },
  { value: 'ThirdParty', label: 'Third Party' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}

export function FulfillmentFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Filter States
  const [isExpedite, setIsExpedite] = useState<boolean | null | undefined>(
    undefined
  );
  const [status, setStatus] = useState<boolean | null>();
  const [origin, setOrigin] = useState<'' | null>();
  const [reset, setReset] = useState<boolean>(false);

  //Entity
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : undefined,
    clientId: queryParams.clientId ? queryParams.clientId : undefined,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : undefined,
    locationId: queryParams.locationId ? queryParams.locationId : undefined,
  });

  const statusRef = useRef<null | Select>(null);
  const originRef = useRef<null | Select>(null);
  const accountRef = useRef<null | Select>(null);
  const clientsRef = useRef<null | Select>(null);
  const isExpediteRef = useRef<null | Select>(null);

  const handleStatus = (params: any) => {
    if (params) {
      setStatus(params.value);
    } else {
      setStatus(undefined);
    }
  };
  const handleOrigin = (params: any) => {
    if (params) {
      setOrigin(params.value);
    } else {
      setOrigin(undefined);
    }
  };
  const handleIsExpedite = (params: any) => {
    if (params) {
      setIsExpedite(params.value);
    } else {
      setIsExpedite(undefined);
    }
  };
  const resetFilters = () => {
    setReset(true);
  };
  useEffect(() => {
    if (reset) {
      accountRef.current?.clearValue();
      clientsRef.current?.clearValue();
      statusRef.current?.clearValue();
      originRef.current?.clearValue();
      isExpediteRef.current?.clearValue();

      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
      };
      setQueryParams({ ...initialFilterFulfillment, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };
    newQueryParams.status = status;
    newQueryParams.origin = origin;
    newQueryParams.expedite = isExpedite;
    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;
    newQueryParams.locationId = entityFilters?.locationId;
    setQueryParams(newQueryParams);
  };

  const showEntitiesFilter = !userGroupRelations.locationId;

  /** START Filter Badges */

  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (!!queryParams.origin) {
      setOrigin(queryParams.origin);
      updateBadge('origin', queryParams.origin, originRef);
    } else {
      clearFilters.push('origin');
    }

    if (!!queryParams.status) {
      setStatus(queryParams.status);
      updateBadge('status', queryParams.status, statusRef);
    } else {
      clearFilters.push('status');
    }

    if (queryParams.expedite || !queryParams.expedite) {
      setIsExpedite(queryParams.expedite);
      if (queryParams.expedite === true || queryParams.expedite === false)
        updateBadge(
          'expedite',
          queryParams.expedite === true
            ? 'Expedite'
            : queryParams.expedite === false
            ? 'No expedite'
            : '',
          isExpediteRef
        );
      else clearFilters.push('expedite');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }

    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');
    if (!queryParams.locationId) clearFilters.push('locationId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }

    // Get initial values from the url
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
      locationId: queryParams.locationId ? queryParams.locationId : undefined,
    };
    setEntityFilters(newQueryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = userGroupRelations.accountId
        ? userGroupRelations.accountId
        : undefined;
      params['clientId'] = userGroupRelations.clientId
        ? userGroupRelations.clientId
        : undefined;
      params['franchiseId'] = userGroupRelations.franchiseId
        ? userGroupRelations.franchiseId
        : undefined;
      params['locationId'] = userGroupRelations.locationId
        ? userGroupRelations.locationId
        : undefined;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      if (ref.current?.type === 'date') {
        ref.current.value = null;
      } else {
        ref.current?.clearValue();
      }
      let params = { ...queryParams };
      params[keyParam] = undefined;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };

  /** END Filter Badges */

  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter
        applyFilters={applyFilter}
        resetFilters={resetFilters}
        scroll={false}
        size={showEntitiesFilter ? 'lg' : 'md'}
      >
        <>
          <div className='row'>
            <div className='col-md-6'>
              <FilterCascadeEntitySelections
                setReset={setReset}
                reset={reset}
                entityFilters={entityFilters}
                setEntityFilters={setEntityFilters}
                level={4}
                setEntityListBadge={setEntityFilteBadges}
                entityFilterBadges={entityFilterBadges}
              />
            </div>
            <div className={showEntitiesFilter ? 'col-md-6' : 'col-md-12'}>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Status</label>

                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='status'
                  id='status'
                  onChange={handleStatus}
                  options={statusOptions}
                  value={
                    status
                      ? statusOptions.filter((x: any) => x.value === status)[0]
                      : null
                  }
                  ref={statusRef}
                  styles={selectStyles}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Origin</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='origin'
                  id='origin'
                  onChange={handleOrigin}
                  options={originOptions}
                  value={
                    originOptions
                      ? originOptions.filter((x: any) => x.value === origin)[0]
                      : null
                  }
                  ref={originRef}
                />
              </div>
              <div className='mb-2' onClick={(e) => e.stopPropagation()}>
                <label className='form-label fw-bold'>Expedite</label>
                <ReactSelect
                  placeholder={<FormattedMessage id='COMMON.ALL' />}
                  isClearable
                  name='isExpedite'
                  id='isExpedite'
                  onChange={handleIsExpedite}
                  options={binaryOptions}
                  value={
                    binaryOptions
                      ? binaryOptions.filter(
                          (x: any) => x.value === isExpedite
                        )[0]
                      : null
                  }
                  ref={isExpediteRef}
                />
              </div>
            </div>
          </div>
        </>
      </CustomFilter>
    </>
  );
}
