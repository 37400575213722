import { Fragment, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../redux';
import { GroupModel } from '../../users/models/GroupModel';
import { OrderViewModel } from '../models/OrderViewModel';
import orderServices from '../services/OrdersServices';
import moment from 'moment';
import { StatusBadge } from '../../../components/StatusBadge';
import { OriginBadge } from '../../../components/OriginBadge';
import DataTable, { TableColumn } from 'react-data-table-component';
import { CancelOrder } from './CancelOrder';
import { toast } from 'react-toastify';
import { OrderStatusLine } from './OrderStatusLine';
import { OrderViewDetailModel } from '../models/OrderViewDetailModel';
import { ReturnOrderModal } from './ReturnOrderModal';
import { urlCompany } from '../../../components/CustomStatusPopover';

interface Params {
  orderId: string;
}

export function OrderView() {
  const { orderId } = useParams<Params>();
  const [order, setOrder] = useState<OrderViewModel | null>(null);
  useEffect(() => {
    if (!order) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, order]);

  const refetch = () => {
    orderServices.getOrder(Number(orderId)).then((res) => {
      setOrder(res.data);
    });
  };

  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const intl = useIntl();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };
  const handleShowCancelModal = () => {
    setShowCancelModal(true);
  };
  const cancel = (values: any) => {
    handleCloseCancelModal();
    orderServices.cancelOrder(Number(orderId), values).then((newOrder) => {
      toast.success('Successfully canceled');
      setOrder(newOrder.data);
    });
  };

  // Return Modal
  const [showReturnModal, setShowReturnModal] = useState(false);
  const handleCloseReturnModal = () => {
    setShowReturnModal(false);
  };
  const handleShowReturnModal = () => {
    setShowReturnModal(true);
  };
  const returnOrder = (values: any) => {
    handleCloseReturnModal();
    orderServices.returnOrder(Number(orderId), values).then((newOrder) => {
      toast.success('Successfully returned');
      goBack();
    });
  };

  const history = useHistory();
  const goBack = () => {
    history.push('/orders/list');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isOrderPicking = () => {
    if (
      order?.status === 'Picking' ||
      order?.status === 'Packed' ||
      order?.status === 'Shipped'
    ) {
      return true;
    }
    return false;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isOrderPacked = () => {
    if (order?.status === 'Packed' || order?.status === 'Shipped') {
      return true;
    }
    return false;
  };
  const isOrderShipped = () => {
    if (order?.status === 'Shipped') {
      return true;
    }
    return false;
  };

  const columns: TableColumn<OrderViewDetailModel>[] = useMemo(
    () => [
      {
        name: 'Qty.',
        selector: (row) => row.quantity,
        wrap: true,
        width: '200px',
        sortable: false,
      },
      {
        name: 'CODE',
        selector: (row) => row.productCode,
        width: '200px',
        sortable: false,
      },
      {
        name: 'PRODUCTS ORDERED',
        cell: (row) => {
          return (
            <>
              <label>{row.productDescription}</label>
              {row.type === 'Kit' && (
                <>
                  <label className='mx-2'>
                    (Kit: {row.kitDescription} - {row.kitCode})
                  </label>
                </>
              )}
            </>
          );
        },

        wrap: true,
        sortable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const customStyles = {
    rows: {
      style: {
        minHeight: '68px',
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        color: '#a1a5b7',

        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#a1a5b7',
        paddingLeft: '50px',
        paddingRight: '50px',
      },
    },
    cells: {
      style: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: '#a1a5b7',

        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#a1a5b7',

        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#a1a5b7',

        fontSize: '16px',
        paddingLeft: '50px',
        paddingRight: '50px',
      },
    },
    pagination: {
      style: {
        justifyContent: 'flex-start',
      },
    },
  };

  // Add Skeleton
  if (!order) return null;

  const isCancellable =
    order?.status !== 'Shipped' &&
    order?.status !== 'BackOrdered' &&
    order?.status !== 'Returned' &&
    order?.status !== 'Cancelled';

  return (
    <Fragment>
      {group.orderPermission?.update && showCancelModal && (
        <CancelOrder
          show={showCancelModal}
          cancel={cancel}
          order={order}
          onHide={handleCloseCancelModal}
        />
      )}
      {group.orderPermission?.update && showReturnModal && (
        <ReturnOrderModal
          show={showReturnModal}
          returnOrder={returnOrder}
          order={order}
          onHide={handleCloseReturnModal}
        />
      )}

      <div className='card'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex align-items-center'>
                <div className='row'>
                  <div className='d-flex align-items-center'>
                    <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                      Order #{order?.id}
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: 'auto' }}>
                  {goBack && (
                    <button
                      onClick={goBack}
                      className='btn btn-light btn-active-primary align-self-center'
                    >
                      <i className='fas fa-arrow-left'></i>
                      {intl.formatMessage({ id: 'ACTION.BACK' })}
                    </button>
                  )}

                  {group.orderPermission?.update && isCancellable && (
                    <button
                      className='m-2 btn btn-primary btn-active-primary align-self-center'
                      onClick={handleShowCancelModal}
                    >
                      Cancel Order
                    </button>
                  )}
                  {group.orderPermission?.update && isOrderShipped() && (
                    <button
                      className='m-2 btn btn-primary btn-active-primary align-self-center'
                      onClick={handleShowReturnModal}
                    >
                      Return Order
                    </button>
                  )}
                </div>
              </div>

              <div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Date:
                      </div>
                      <div className='fw-bolder fs-6 text-dark col-9'>
                        {moment(order?.orderDate).format('MM-DD-YYYY')}
                      </div>
                    </div>

                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Client:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.client}
                      </div>
                    </div>

                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Franchise:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.franchise}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Location:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.location}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Ship to:
                      </div>
                      <div className='fw-bolder fs-6 text-dark col-9'>
                        {order?.orderLocation.addressLine1}{' '}
                        {order?.orderLocation.postalCode}{' '}
                        {order?.orderLocation.city} {order?.orderLocation.state}
                      </div>
                    </div>

                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Ship method:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.shippingCompany} {order?.shippingMethod}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Tracking Number:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        <Fragment>
                          {order?.shippingTrackingNbr ? (
                            <a
                              href={`${urlCompany(
                                order?.shippingCompany,
                                order?.shippingTrackingNbr
                              )}`}
                              className='fw-bolder'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {order?.shippingTrackingNbr}
                            </a>
                          ) : (
                            ''
                          )}
                        </Fragment>
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Status:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {order?.status && <StatusBadge status={order.status} />}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Created by:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.client}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Origin:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {order?.origin && <OriginBadge origin={order.origin} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {group.orderPermission && (
        <OrderStatusLine
          order={order}
          permissions={group.orderPermission}
        ></OrderStatusLine>
      )}

      <div className='card'>
        <div className='card-body'>
          <div className='px-6 px-lg-10 px-xxl-15'>
            {order?.orderDetails && (
              <DataTable
                columns={columns}
                data={order.orderDetails}
                customStyles={customStyles}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
