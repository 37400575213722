import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux';
import { GroupModel } from '../../users/models/GroupModel';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { NavTabs } from '../../../components/NavTabs';
import { CustomBadge } from '../../../components/CustomBadge';
import warehouseServices from '../services/WarehousesServices';
import { InventoryModel } from '../models/InventoryModel';
import { WarehouseEditInventory } from './WarehouseEditInventory';
import moment from 'moment';
import { ShippingUnitList } from './ShippingUnitList';
import {
  toAbsoluteUrl,
  toAbsoluteUrlImage,
} from '../../../../_metronic/helpers';

interface Params {
  warehouseId: string;
  productId: string;
}

export function WarehouseInventoryView() {
  const { warehouseId, productId } = useParams<Params>();
  const [inventory, setInventory] = useState<InventoryModel | null>(null);
  const [lowColor, setLowColor] = useState<string>('');

  useEffect(() => {
    if (!inventory) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, productId, inventory]);

  const refetch = () => {
    warehouseServices
      .getInventoryDetails(Number(warehouseId), Number(productId))
      .then((res) => {
        setInventory(res.data);
        const currentLevel = res?.data?.currentLevel
          ? res?.data?.currentLevel
          : 0;
        const lowLevel = res?.data?.lowLevel ? res?.data?.lowLevel : 0;
        const quantityOnOrder = res?.data?.quantityOnOrder
          ? res?.data?.quantityOnOrder
          : 0;
        const color =
          currentLevel <= lowLevel
            ? 'badge-success'
            : currentLevel - quantityOnOrder <= lowLevel
            ? 'badge-warning'
            : '';
        setLowColor(color);
      });
  };

  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const intl = useIntl();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    refetch();
  };
  const handleShowUpdateModal = () => {
    setShowUpdateModal(true);
  };

  const menus = [
    {
      title: 'Shipping Units',
      path: `/warehouses/${warehouseId}/inventories/${productId}`,
      isActive: true,
    },
  ];
  const history = useHistory();
  const goBack = () => {
    history.push(`/warehouses/${warehouseId}`);
  };

  // TODO: add skeleton
  if (!inventory) {
    return <></>;
  }

  return (
    <>
      {group.warehouseInventoryPermission?.update &&
        showUpdateModal &&
        inventory.productId && (
          <WarehouseEditInventory
            refetch={refetch}
            productId={inventory.productId}
            warehouseId={inventory.warehouseId}
            show={showUpdateModal}
            onHide={handleCloseUpdateModal}
          />
        )}

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='row'>
                <div className='col-2 d-flex align-items-center justify-content-center '>
                  {inventory.productImagePath && (
                    <div className=' my-2 d-flex justify-content-center'>
                      <img
                        style={{
                          height: 'auto',
                          width: 'auto',
                          maxWidth: '180px',
                        }}
                        src={toAbsoluteUrlImage(inventory.productImagePath)}
                        alt='Valid'
                      />
                    </div>
                  )}
                  {!inventory.productImagePath && (
                    <div className=' my-2 d-flex justify-content-center'>
                      <img
                        style={{ height: '150px', width: 'auto' }}
                        src={toAbsoluteUrl('/media/images/no-image.png')}
                        alt='Valid'
                      />
                    </div>
                  )}
                </div>
                <div className='col-10'>
                  <div className='d-flex align-items-center'>
                    <div className='row'>
                      <div className='d-flex align-items-center'>
                        <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                          {inventory.productCode +
                            ' - ' +
                            inventory.productDescription}
                        </div>
                        <div className='ms-4'>
                          <CustomBadge
                            status={!!inventory.productIsActive}
                            activeText='Active'
                            inactiveText='Inactive'
                          />
                          {inventory.productDeactivationReason && (
                            <span className='text-muted ms-4'>
                              {inventory.productIsActive
                                ? ''
                                : inventory.productDeactivationReason}
                            </span>
                          )}
                        </div>
                        {!!lowColor && (
                          <div className='ms-2'>
                            <div className='col-3'>
                              <span
                                className={`badge ${lowColor} mx-3 align-right`}
                              >
                                Low
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ marginLeft: 'auto' }}>
                      {goBack && (
                        <button
                          onClick={goBack}
                          className='btn btn-light btn-active-primary align-self-center'
                        >
                          <i className='fas fa-arrow-left'></i>
                          {intl.formatMessage({ id: 'ACTION.BACK' })}
                        </button>
                      )}

                      {group.warehouseInventoryPermission?.update && (
                        <button
                          onClick={() => handleShowUpdateModal()}
                          className='m-2 btn  btn-primary btn-active-primary align-self-center'
                        >
                          <i className='las la-edit fs-2'></i>
                          {intl.formatMessage({ id: 'ACTION.EDIT' })}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div
                        style={{ marginTop: '1em', marginBottom: '3em' }}
                        className='row '
                      >
                        <span className='col-md-6 fw-bolder fs-3 text-muted'>
                          Warehouse
                        </span>
                        <span className='col-md-6  fw-bolder fs-3 text-dark'>
                          {inventory.warehouseName}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Current Level
                        </span>
                        <span className='col-md-6  fs-6 text-dark'>
                          {inventory.currentLevel
                            ? inventory.currentLevel
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Low Level
                        </span>
                        <span className='col-md-6   fs-6 text-dark'>
                          {inventory.lowLevel ? inventory.lowLevel : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Qty. on order
                        </span>
                        <span className='col-md-6   fs-6 text-dark'>
                          {inventory.quantityOnOrder
                            ? inventory.quantityOnOrder
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='row my-1'>
                        <span className='col-md-6 fw-bolder fs-6 text-muted'>
                          Most recent usage
                        </span>
                        <span className='col-md-6  fs-6 text-dark'>
                          {inventory.mostRecentUsageDate
                            ? moment(inventory.mostRecentUsageDate).format(
                                'MM-DD-YYYY'
                              )
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <NavTabs submenu={menus} />
          </div>

          <Switch>
            <Route path='/warehouses/:warehouseId/inventories/:productId' exact>
              <ShippingUnitList inventory={inventory}></ShippingUnitList>
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}
