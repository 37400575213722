import { Field, useFormikContext } from 'formik';
import { CustomInput } from '../../../components/CustomInput';
import { CustomTextArea } from '../../../components/CustomTextArea';
import { CreateKitModel } from '../models/CreateKitModel';

export function KitInformationStep1() {
  const { values } = useFormikContext<CreateKitModel>();

  return (
    <div className='w-100'>
      <div className='row'>
        <div className='fw-bolder text-gray-800 fs-2 mb-10'>
          General Information
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-6'>
          <CustomInput
            isRequired={true}
            id='code'
            name='code'
            type='text'
            label='Code'
            isDisabled={values.id > 0 ? true : false}
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-12'>
          <CustomTextArea
            isRequired={true}
            id='description'
            name='description'
            label='Description'
            maxLength={255}
          />
        </div>
      </div>
      <div className='fv-row my-8'>
        <div className='d-flex flex-start'>
          <div className='me-1'>
            <label className='form-label form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
              Premium
            </label>
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              className={`form-check-input me-1 ${
                values.premium ? 'bg-primary' : ''
              }`}
              name='premium'
              type='checkbox'
            />

            <span className='form-check-label fw-bold text-muted'>
              {values.premium ? 'Yes' : 'No'}
            </span>
          </label>
        </div>
      </div>
      <div className='row my-8'>
        <div className='fw-bolder text-gray-800 fs-2 m'>Order Management</div>
      </div>
      <div className='fv-row my-4'>
        <div className='d-flex flex-start'>
          <div className='me-1'>
            <label className='form-label form-check form-check-sm form-check-custom  form-check-solid me-5 me-lg-20'>
              Web Orderable
            </label>
          </div>

          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              className={`form-check-input me-1 ${
                values.webOrderable ? 'bg-primary' : ''
              }`}
              name='webOrderable'
              type='checkbox'
            />

            <span className='form-check-label fw-bold text-muted'>
              {values.webOrderable ? 'Yes' : 'No'}
            </span>
          </label>
        </div>
      </div>
      <div className='row my-8'>
        <div className='col-6'>
          <CustomInput
            id='maxOrderQuantity'
            name='maxOrderQuantity'
            placeholder='Enter value'
            type='number'
            label='Max Order Quantity'
          />
        </div>
        <div className='col-6'>
          <CustomInput
            id='shippingUnitSize'
            name='shippingUnitSize'
            type='number'
            label='Shipping Unit Size'
          />
        </div>
      </div>
    </div>
  );
}
