//interface Props {}

import CustomImageInput from '../../../components/CustomImageInput';

export function KitImagesStep3() {
  return (
    <div className='w-100'>
      <div className='card-body p-3'>
        <div className='row'>
          <div className='fw-bolder fs-2'>Kit Images</div>
          <div className='fs-5 mt-0 text-muted mb-7'>
            Select wether the card has fixed value or allows a range
          </div>
        </div>

        <div className='row mb-6'>
          <div className='col-lg-6'>
            <CustomImageInput
              title='Upload Image 1'
              name='files[0]'
              accept='.png, .jpg'
            ></CustomImageInput>
          </div>

          <div className='col-lg-6'>
            <CustomImageInput
              title='Upload Image 2'
              name='files[1]'
              accept='.png, .jpg'
            ></CustomImageInput>
          </div>
        </div>
      </div>
    </div>
  );
}
