import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { KitModel } from '../models/KitModel';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux';
import { GroupModel } from '../../users/models/GroupModel';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { NavTabs } from '../../../components/NavTabs';
import kitServices from '../services/KitsServices';
import { KitEdit } from './KitEdit';
import { IsActiveComponent } from '../../../components/IsActiveComponent';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { KitViewInventory } from './KitViewInventory';
import { KitViewProducts } from './KitViewProducts';
import { CustomBadge } from '../../../components/CustomBadge';
import { ViewImages } from '../../../components/ViewImages';
import { ViewAvailabilities } from '../../../components/ViewAvailabilities';

interface Params {
  kitId: string;
}

export function KitView() {
  const { kitId } = useParams<Params>();
  const [kit, setKit] = useState<KitModel | null>(null);

  useEffect(() => {
    if (!kit) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitId, kit]);

  const refetch = () => {
    kitServices.getKit(Number(kitId)).then((res) => {
      setKit(res.data);
    });
  };

  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const intl = useIntl();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    refetch();
  };
  const handleShowUpdateModal = () => {
    setShowUpdateModal(true);
  };
  const [showActivateModal, setShowActivateModal] = useState(false);
  const handleCloseActivateModal = () => {
    setShowActivateModal(false);
    refetch();
  };
  const handleShowActivateModal = () => {
    setShowActivateModal(true);
  };
  const [showCloneModal, setShowCloneModal] = useState(false);
  const handleCloseCloneModal = () => {
    setShowCloneModal(false);
  };
  const handleShowCloneModal = () => {
    setShowCloneModal(true);
  };

  const menus = [
    {
      title: 'Inventory',
      path: `/kits/${kitId}`,
      isActive: true,
    },
    {
      title: 'Products in Kit',
      path: `/kits/${kitId}/products`,
      isActive: false,
    },
    {
      title: 'Kit Images',
      path: `/kits/${kitId}/kit-images`,
      isActive: false,
    },
    {
      title: 'Availability',
      path: `/kits/${kitId}/availability`,
      isActive: false,
    },
  ];
  const history = useHistory();
  const goBack = () => {
    history.push(`/kits`);
  };

  // TODO: add skeleton
  if (!kit) {
    return <></>;
  }

  const clone = () => {
    handleCloseCloneModal();
    kitServices.clone(kit.id).then(() => {
      toast.success('Successful cloned');
    });
  };
  const setActivate = (kitId: number) => {
    kitServices.activateKit(kitId).then(() => {
      toast.success('Successful activation');
      refetch();
    });
  };

  const setDeactivate = (kitId: number, reason?: string) => {
    kitServices.desactivateKit(kitId, reason).then(() => {
      toast.success('Successful deactivation');
      refetch();
    });
  };

  return (
    <>
      {group.kitPermission?.update && (
        <IsActiveComponent
          id={kit.id}
          isActive={kit.isActive}
          onHide={handleCloseActivateModal}
          show={showActivateModal}
          setDeactivate={setDeactivate}
          setActivate={setActivate}
        />
      )}

      {group.kitPermission?.update && showUpdateModal && (
        <KitEdit
          kitId={kit.id}
          refetch={refetch}
          show={showUpdateModal}
          onHide={handleCloseUpdateModal}
        />
      )}

      {group.kitPermission?.create && showCloneModal && (
        <ConfirmationModal
          message='Are you sure to clone this kit?'
          show={showCloneModal}
          onCancel={handleCloseCloneModal}
          onAcept={clone}
        />
      )}

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex align-items-center'>
                <div className='row'>
                  <div className='d-flex align-items-center'>
                    <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                      {kit.code}
                    </div>
                    <div className='ms-4'>
                      <CustomBadge
                        status={!!kit.isActive}
                        activeText='Active'
                        inactiveText='Inactive'
                      />
                      {kit.deactivationReason && (
                        <span className='text-muted ms-4'>
                          {kit.isActive ? '' : kit.deactivationReason}
                        </span>
                      )}
                    </div>
                    {group.kitPermission?.update && (
                      <div className='form-check form-switch form-check-custom  mx-5'>
                        <input
                          className={`form-check-input ${
                            kit.isActive ? 'bg-primary text-white' : ''
                          } `}
                          checked={kit.isActive}
                          type='checkbox'
                          onChange={() => handleShowActivateModal()}
                        />
                        <label
                          className={`form-check-label ${
                            kit.isActive ? 'text-muted' : 'text-muted'
                          } `}
                        >
                          {!kit.isActive ? 'Activate' : 'Deactivate'}
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div style={{ marginLeft: 'auto' }}>
                  {goBack && (
                    <button
                      onClick={goBack}
                      className='btn btn-light btn-active-primary align-self-center'
                    >
                      <i className='fas fa-arrow-left'></i>
                      {intl.formatMessage({ id: 'ACTION.BACK' })}
                    </button>
                  )}

                  {group.kitPermission?.create && (
                    <button
                      onClick={() => handleShowCloneModal()}
                      className='m-2 btn btn-light btn-active-primary align-self-center'
                    >
                      <i className='fas fa-clone'></i>
                      Clone
                    </button>
                  )}

                  {group.kitPermission?.update && (
                    <button
                      onClick={() => handleShowUpdateModal()}
                      className='m-2 btn  btn-primary btn-active-primary align-self-center'
                    >
                      <i className='las la-edit fs-2'></i>
                      {intl.formatMessage({ id: 'ACTION.EDIT' })}
                    </button>
                  )}
                </div>
              </div>
              <div>
                <div className='row my-2'>
                  <div className='fw-bolder fs-6 text-muted col'>Kit Code</div>
                  <div className='fw-bolder fs-6 text-dark col-10'>
                    {' '}
                    {kit.code}
                  </div>
                </div>

                <div className='row my-2'>
                  <div className='fw-bolder fs-6 text-muted col'>
                    Description
                  </div>
                  <div className='fw-bolder fs-6 text-dark col-10'>
                    {' '}
                    {kit.description}
                  </div>
                </div>

                <div className='row my-2'>
                  <div className='fw-bolder fs-6 text-muted col'>Premium</div>
                  <div className='fw-bolder fs-6 text-dark col-10'>
                    <CustomBadge
                      status={!!kit.premium}
                      activeText='Yes'
                      inactiveText='No'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <NavTabs submenu={menus} />
          </div>

          <Switch>
            <Route path='/kits/:kitId' exact>
              <KitViewInventory kit={kit} />
            </Route>
            <Route path='/kits/:kitId/products' exact>
              <KitViewProducts kit={kit} />
            </Route>
            <Route path='/kits/:kitId/kit-images' exact>
              <ViewImages files={kit.files} />
            </Route>
            <Route path='/kits/:kitId/availability' exact>
              <ViewAvailabilities availabilities={kit.availabilities} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}
