import { Fragment, useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/dist/declarations/src/Select';
import { CustomFilter } from '../../../../components/CustomFilter';
import ReactSelect from 'react-select';
import { RootState } from '../../../../redux';
import { UserGroupRelationModel } from '../../../auth/models/UserGroupRelationModel';
import { FilterCascadeEntitySelections } from '../../../../components/FilterCascadeEntitySelection';
import { EntityFiltersModel } from '../../../../models/EntityFiltersModel';
import { initialFilterLocationsMaster } from '../../utils/utils';
import { CustomBadgeFilter } from '../../../../components/CustomBadgeFilter';
import { FilterBadge } from '../../../../models/FilterBadge';

const activeOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];
interface PropsFilter {
  queryParams: any;
  setQueryParams: any;
}

export function LocationsMasterFilter({
  setQueryParams,
  queryParams,
}: PropsFilter) {
  // Filter States
  const [isActive, setIsActive] = useState<boolean | null>();
  const isActiveRef = useRef<null | Select>(null);

  const [reset, setReset] = useState<boolean>(false);
  const [entityFilters, setEntityFilters] = useState<EntityFiltersModel>({
    accountId: queryParams.accountId ? queryParams.accountId : null,
    clientId: queryParams.clientId ? queryParams.clientId : null,
    franchiseId: queryParams.franchiseId ? queryParams.franchiseId : null,
    locationId: null,
  });
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  // Get initial values from the url
  useEffect(() => {
    let clearFilters = [];

    if (queryParams.active || !queryParams.active) {
      setIsActive(queryParams.active);
      if (queryParams.active === true || queryParams.active === false)
        updateBadge(
          'active',
          queryParams.active === true
            ? 'Active'
            : queryParams.active === false
            ? 'Inactive'
            : '',
          isActiveRef
        );
      else clearFilters.push('active');
    }
    if (entityFilterBadges.length > 0) {
      entityFilterBadges.forEach((item: FilterBadge) => {
        updateBadge(item.key, item.value, item.ref);
      });
    }
    if (!queryParams.accountId) clearFilters.push('accountId');
    if (!queryParams.clientId) clearFilters.push('clientId');
    if (!queryParams.franchiseId) clearFilters.push('franchiseId');

    if (clearFilters.length > 0) {
      clearMultipleFilter(clearFilters);
    }
    const newQueryParams = {
      ...entityFilters,
      accountId: queryParams.accountId ? queryParams.accountId : undefined,
      clientId: queryParams.clientId ? queryParams.clientId : undefined,
      franchiseId: queryParams.franchiseId
        ? queryParams.franchiseId
        : undefined,
    };
    setEntityFilters(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const handleIsActive = (params: any) => {
    if (params) {
      setIsActive(params.value);
    } else {
      setIsActive(undefined);
    }
  };

  const resetFilters = () => {
    // Clear All Ref
    isActiveRef.current?.clearValue();
    // Update QueryParams: Review if
    setReset(true);
  };

  const applyFilter = () => {
    const newQueryParams = { ...queryParams };

    newQueryParams.accountId = entityFilters?.accountId;
    newQueryParams.clientId = entityFilters?.clientId;
    newQueryParams.franchiseId = entityFilters?.franchiseId;

    newQueryParams.active = isActive;
    newQueryParams.pageNumber = 1;
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    if (reset) {
      let newQueryParams = {
        accountId: userGroupRelations.accountId
          ? userGroupRelations.accountId
          : undefined,
        clientId: userGroupRelations.clientId
          ? userGroupRelations.clientId
          : undefined,
        franchiseId: userGroupRelations.franchiseId
          ? userGroupRelations.franchiseId
          : undefined,
      };
      setQueryParams({ ...initialFilterLocationsMaster, ...newQueryParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  /** START Filter Badges */
  const [filterBadges, setFilteBadges] = useState<FilterBadge[]>([]);
  const [entityFilterBadges, setEntityFilteBadges] = useState<FilterBadge[]>(
    []
  );
  const clearMultipleFilter = (filters: Array<string>) => {
    let badges = filterBadges;
    filters.forEach((element) => {
      badges = badges.filter((item: FilterBadge) => item.key !== element);
    });
    setFilteBadges(badges);
  };
  const clearFilter = (ref: any, keyParam: string) => {
    if (
      keyParam === 'accountId' ||
      keyParam === 'clientId' ||
      keyParam === 'franchiseId'
    ) {
      clearMultipleFilter([
        'accountId',
        'clientId',
        'franchiseId',
        'locationId',
      ]);
      setEntityFilteBadges([]);
      let params = { ...queryParams };
      params['accountId'] = undefined;
      params['clientId'] = undefined;
      params['franchiseId'] = undefined;
      params['locationId'] = undefined;
      setQueryParams(params);

      filterBadges
        .find((element) => element.key === 'accountId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'clientId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'franchiseId')
        ?.ref.current?.clearValue();
      filterBadges
        .find((element) => element.key === 'locationId')
        ?.ref.current?.clearValue();
    } else if (ref) {
      ref.current?.clearValue();
      let params = { ...queryParams };
      params[keyParam] = undefined;
      setQueryParams(params);
    }

    setFilteBadges(
      filterBadges.filter((item: FilterBadge) => item.key !== keyParam)
    );
  };
  const updateBadge = (key: string, value: string, ref: any) => {
    let badges = filterBadges;
    const finded = badges?.find((item: FilterBadge) => item.key === key);
    if (finded && finded !== undefined) {
      badges.splice(badges.indexOf(finded), 1, {
        key: key,
        value: value,
        ref: ref,
      });
    } else {
      badges.push({
        key: key,
        value: value,
        ref: ref,
      });
    }
    setFilteBadges(badges);
  };
  /** END Filter Badges */
  return (
    <>
      {filterBadges?.map((item: FilterBadge) => {
        if (item.value && item.value !== '')
          return (
            <CustomBadgeFilter
              text={item.value}
              key={item.key}
              badgeKey={item.key}
              clearFilter={() => {
                if (item.ref) clearFilter(item.ref, item.key);
              }}
            ></CustomBadgeFilter>
          );
        else return <></>;
      })}
      <CustomFilter applyFilters={applyFilter} resetFilters={resetFilters}>
        <div onClick={(e) => e.stopPropagation()}>
          <Fragment>
            <FilterCascadeEntitySelections
              setReset={setReset}
              reset={reset}
              entityFilters={entityFilters}
              setEntityFilters={setEntityFilters}
              level={3}
              setEntityListBadge={setEntityFilteBadges}
              entityFilterBadges={entityFilterBadges}
            />
            <label className='form-label fw-bold'>Active</label>
            <ReactSelect
              placeholder={<FormattedMessage id='COMMON.ALL' />}
              isClearable
              name='isActive'
              id='isActive'
              onChange={handleIsActive}
              options={activeOptions}
              value={
                activeOptions
                  ? activeOptions.filter((x: any) => x.value === isActive)[0]
                  : null
              }
              ref={isActiveRef}
              defaultValue={activeOptions[0]}
            />
          </Fragment>
        </div>
        {/* Add more filters */}
      </CustomFilter>
    </>
  );
}
