import { Fragment, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import {
  initialFilterLocationsMaster,
  queryParamsConfigLocationsMaster,
} from '../../utils/utils';
import ListComponent from '../../../../components/ListComponent';
import locationsServices from '../../services/LocationsServices';
import { useDebouncedCallback } from 'use-debounce/lib';
import { RootState } from '../../../../redux';
import { shallowEqual, useSelector } from 'react-redux';
import { LocationListModel } from '../../models/LocationListModel';
import { useQueryParamsReplace } from '../../../../utils/useQueryParamsReplace';
import { GroupModel } from '../../../users/models/GroupModel';
import { LocationsMasterFilter } from './LocationsMasterFilter';
import { ImportLocationsModal } from './ImportLocationsModal';

export function LocationsMasterList() {
  //Permission Group
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );
  // Pagination, Filter and Query Params
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState<LocationListModel[]>([]);
  const [filter, setFilter] = useState('');
  const [showImportLocations, setShowImportLocations] = useState(false);
  const handleShowImportLocationsModal = () => {
    setShowImportLocations(true);
  };
  const handleCloseImportLocationsModal = () => {
    setShowImportLocations(false);
  };

  const [queryParams, setQueryReplace] = useQueryParamsReplace(
    queryParamsConfigLocationsMaster
  );
  const history = useHistory();

  // useMemo will only be created once
  const columns: TableColumn<LocationListModel>[] = useMemo(
    () => [
      {
        name: 'Account',
        selector: (row) => row.accountName,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Franchise',
        selector: (row) => row.franchiseName,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Client Name',
        selector: (row) => row.clientName,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: 'Merchant Id',
        selector: (row) => row.franchiseNumber,
        minWidth: '120px',
        wrap: true,
      },
      {
        name: 'Location No.',
        selector: (row) => row.number,
        sortable: true,
        sortField: 'number',
        center: true,
        minWidth: '120px',
        wrap: true,
      },
      {
        name: 'Location Name',
        selector: (row) => row.name,
        sortable: true,
        sortField: 'name',
        wrap: true,
        minWidth: '200px',
      },
      {
        name: 'Active',
        cell: (row) => {
          const badgeText = row.isActive ? 'Active' : 'Inactive';
          const color = row.isActive ? 'badge-success' : 'badge-light';
          return <span className={`badge ${color}`}>{badgeText}</span>;
        },
        sortable: false,
      },
      {
        name: 'Auto Replenish',
        cell: (row) => {
          const badgeText = row.autoReplenish ? 'Yes' : 'No';
          const color = row.autoReplenish ? 'badge-success' : 'badge-light';
          return <span className={`badge ${color}`}>{badgeText}</span>;
        },
        sortable: false,
        minWidth: '150px',
      },
      {
        name: 'Ship-to City',
        selector: (row) => row.shipToCity,
        minWidth: '150px',
      },
      {
        name: 'Ship-to State',
        selector: (row) => row.shipToState,
        minWidth: '150px',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  if (group.locationPermission?.view && group.locationPermission?.update) {
    columns.push({
      name: 'Actions',
      button: true,
      cell: (row) =>
        row.id && (
          <>
            <Fragment>
              <div></div>
              {group.locationPermission?.view && (
                <button
                  onClick={() => handleView(row)}
                  className='btn btn-icon btn-bg-light btn-active-success btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='View'
                >
                  <i className='las la-eye fs-2'></i>
                </button>
              )}
              {group.orderPermission?.create && (
                <button
                  onClick={() => handleCreateOrder(row)}
                  className='btn btn-icon btn-bg-light btn-active-primary btn-sm me-1'
                  data-bs-toggle='tooltip'
                  title='Create Order'
                >
                  <i className='fas fa-shopping-cart'></i>
                </button>
              )}
            </Fragment>
          </>
        ),
    });
  }

  const handleView = (location: LocationListModel) => {
    history.push(
      `/accounts/${location.accountId}/clients/${location.clientId}/franchises/${location.franchiseId}/locations/${location.id}`
    );
  };
  const handleCreateOrder = (location: LocationListModel) => {
    history.push(
      `/orders/create-selection/${location.accountId}/${location.clientId}/${location.franchiseId}/${location.id}`
    );
  };
  //Initialization methods
  useEffect(() => {
    if (history.location.search === '') {
      setQueryReplace({
        ...initialFilterLocationsMaster,
      });
    }
    if (queryParams.filter) setFilter(queryParams.filter);

    if (queryParams.pageNumber) findLocations(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const findLocations = function (queryParams: any) {
    if (group.locationPermission?.list) {
      locationsServices.findLocations(queryParams).then((response) => {
        setEntities(response.data.items);
        setTotalCount(response.data.totalCount);
      });
    }
  };

  const debounced = useDebouncedCallback((params: any) => {
    const newQueryParams = { ...queryParams };
    if (params.key === 'filter') {
      let filter = params.value;
      newQueryParams.filter = filter;
      newQueryParams.pageNumber = 1;
      setQueryReplace(newQueryParams);
    }
  }, 800);
  const onFilterChange = (e: any, key: string) => {
    if (key === 'filter') setFilter(e.target.value);
    debounced({ key: key, value: e.target.value });
  };
  const refetch = () => {
    findLocations(queryParams);
  };

  return (
    <>
      {group.locationPermission?.create && showImportLocations && (
        <ImportLocationsModal
          refetch={refetch}
          show={showImportLocations}
          onHide={handleCloseImportLocationsModal}
        ></ImportLocationsModal>
      )}

      <div
        style={{
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
        }}
        className='card'
      >
        {(group.locationPermission?.list ||
          group.locationPermission?.create) && (
          <div className='card-header py-6'>
            <div className='card-title'>
              {group.locationPermission?.list && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <i className='las la-search position-absolute ms-6 fs-2'></i>
                  <input
                    autoComplete='none'
                    type='search'
                    className='form-control form-control-solid w-350px ps-15'
                    value={filter}
                    placeholder='Search by Name'
                    onChange={(e) => onFilterChange(e, 'filter')}
                  />
                </div>
              )}
            </div>
            <div className='card-toolbar'>
              {group.locationPermission?.list && (
                <LocationsMasterFilter
                  queryParams={queryParams}
                  setQueryParams={setQueryReplace}
                />
              )}
              {group.locationPermission?.create && (
                <>
                  <button
                    type='button'
                    className='btn btn-primary align-self-center'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    onClick={handleShowImportLocationsModal}
                  >
                    {/* <i className='bi bi-plus fs-2'></i> */}
                    Import Locations
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        <div className='card-body px-9 py-3'>
          <ListComponent
            table={{
              columns,
              data: entities,
              paginationTotalRows: totalCount,
              pointerOnHover: true,
              highlightOnHover: true,
              onRowClicked: handleView,
            }}
            queryParams={queryParams}
            setQueryParams={setQueryReplace}
            notPermissions={!group.locationPermission?.list}
          />
        </div>
      </div>
    </>
  );
}
