/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik, Form, FormikValues, FormikContext, FormikProps } from 'formik';
import { Modal } from 'react-bootstrap-v5';
import { ProductModel } from '../models/ProductModel';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { createProductSchemas } from '../utils/createProductSchemasValidations';
import { ProductImagesStep3 } from './ProductImagesStep3';
import { ProductCardAttributesStep2 } from './ProductCardAttributesStep2';
import { ProductInformationStep1 } from './ProductInformationStep1';
import { useIntl } from 'react-intl';
import productServices from '../services/ProductsServices';
import { productHelpers } from '../helpers/product';
import { handle400Error } from '../../../utils/handle400Error';
import { AxiosResponse } from 'axios';
import { ProductInformationUpdate } from '../models/ProductInformationUpdate';
import { CardAttributeModel } from '../models/ProductModel';
import { toast } from 'react-toastify';
import { ProductAvailabilityStep4 } from './ProductAvailabilityStep4';
interface Props {
  refetch: () => void;
  show: boolean;
  onHide: () => void;
  productId: number;
}

export function ProductEdit({ show, refetch, onHide, productId }: Props) {
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<ProductModel | null>(null);
  const [lastStep, setLastStep] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(
    createProductSchemas(intl)[0]
  );

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    setLoading(true);
    productServices
      .getProduct(productId)
      .then((productData: AxiosResponse<ProductModel>) => {
        const data = productHelpers.initialValues(productData.data);
        setProduct(data);
        setLoading(false);
      });
  }, [productId]);

  const prevStep = (values: ProductModel) => {
    if (!stepper.current) {
      return;
    }
    const currentIndex = stepper.current.currentStepIndex;
    const totatStepsNumber = stepper.current.totatStepsNumber;
    let backIndex = currentIndex - 1;
    if (currentIndex === 3 && values.productType !== 'Card') {
      backIndex = 1;
    }
    setLastStep(backIndex === totatStepsNumber);

    setCurrentSchema(createProductSchemas(intl)[backIndex - 1]);
    stepper.current.goto(backIndex);
  };
  const stepperClick = (step: number) => {
    if (!stepper.current) {
      return;
    }

    const totatStepsNumber = stepper.current.totatStepsNumber;
    setLastStep(step === totatStepsNumber);
    setCurrentSchema(createProductSchemas(intl)[step - 1]);

    stepper.current.goto(step);
  };

  const handleSubmitClick = (
    type: 'close' | 'continue',
    formikProps: FormikProps<any>
  ) => {
    const { values, isValid } = formikProps;
    if (isValid) {
      submitStep(values, formikProps, type === 'close' ? true : false);
    }
  };
  const submitStep = (
    values: ProductModel,
    actions: FormikValues,
    closeModal: boolean
  ) => {
    if (!stepper.current) {
      return;
    }
    const currentIndex = stepper.current.currentStepIndex;
    const totatStepsNumber = stepper.current.totatStepsNumber;
    let nextIndex = currentIndex + 1;

    if (currentIndex === 1 && values.productType !== 'Card') {
      nextIndex = 3;
    }
    setLastStep(nextIndex === totatStepsNumber);
    setCurrentSchema(createProductSchemas(intl)[nextIndex - 1]);
    if (currentIndex === 1) {
      step1(nextIndex, values, actions, closeModal);
    }
    if (currentIndex === 2) {
      step2(nextIndex, values, actions, closeModal);
    }
    if (currentIndex === 3) {
      step3(nextIndex, values, actions, closeModal);
    }
    if (currentIndex === 4) {
      step4(nextIndex, values, actions, closeModal);
    }
  };
  const step1 = useCallback(
    (
      nextIndex: number,
      product: ProductModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      const body: ProductInformationUpdate =
        productHelpers.updateStep1(product);
      productServices
        .productInformationUpdate(body)
        .then((values) => {
          if (closeModal) {
            refetch();
            onHide();
            toast.success('Product successfully updated');
          } else if (stepper.current) {
            stepper.current.goto(nextIndex);
          }
        })
        .catch((error) => {
          setCurrentSchema(createProductSchemas(intl)[0]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const step2 = useCallback(
    (
      nextIndex: number,
      product: ProductModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      const body: CardAttributeModel = productHelpers.step2(product);
      productServices
        .productCardAttributeUpdate(product.id, body)
        .then((values) => {
          const body = values.data;
          if (closeModal) {
            refetch();
            onHide();
            toast.success('Product successfully updated');
          } else if (stepper.current) {
            stepper.current.goto(nextIndex);
          }
        })
        .catch((error) => {
          setCurrentSchema(createProductSchemas(intl)[1]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const step3 = useCallback(
    (
      nextIndex: number,
      product: ProductModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      const body: any = productHelpers.createFormDataFiles(product.files);
      productServices
        .productImages(product.id, body)
        .then((values) => {
          if (closeModal) {
            refetch();
            onHide();
            toast.success('Product successfully updated');
          } else if (stepper.current) {
            stepper.current.goto(nextIndex);
            product.files = productHelpers.processFiles(values.data.files);
          }
        })
        .catch((error) => {
          setCurrentSchema(createProductSchemas(intl)[2]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const step4 = useCallback(
    (
      nextIndex: number,
      product: ProductModel,
      actions: FormikValues,
      closeModal: boolean
    ) => {
      productServices
        .productAvailabilities(product.id, product.availabilities)
        .then((values) => {
          if (stepper.current) {
            refetch();
            onHide();
            toast.success('Product successfully updated');
          }
        })
        .catch((error) => {
          setCurrentSchema(createProductSchemas(intl)[3]);
          if (error?.response?.status === 400) {
            handle400Error(error.response.data.errors, actions.setErrors);
            if (stepper.current) {
              stepper.current.goto(4);
            }
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    if (!loading && product) {
      loadStepper();
    }
  }, [stepperRef, show, loading, product]);
  return (
    <>
      {!loading && product && (
        <Modal
          show={show}
          onHide={onHide}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header className='px-9 pt-7 card-rounded'>
            <Modal.Title className='m-0 fw-bolder fs-3'>
              Edit Product
            </Modal.Title>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={onHide}
            >
              <i className='las la-times fs-2'></i>
            </div>
          </Modal.Header>
          <Modal.Body className='mt-1 px-6 py-3 position-relative z-index-1'>
            <div
              ref={stepperRef}
              className='stepper stepper-links d-flex flex-column pt-5'
              id='kt_create_account_stepper'
            >
              <div className='stepper-nav mb-5'>
                <div
                  className='stepper-item current'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(1)}
                >
                  <h3 className='stepper-title'>Product Information</h3>
                </div>

                <div
                  className='stepper-item'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() =>
                    product.productType === 'Card' && stepperClick(2)
                  }
                >
                  <h3 className='stepper-title'>Card Attributes</h3>
                </div>

                <div
                  className='stepper-item'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(3)}
                >
                  <h3 className='stepper-title'>Product Images</h3>
                </div>

                <div
                  className='stepper-item'
                  data-kt-stepper-element='nav'
                  data-kt-stepper-action='step'
                  onClick={() => stepperClick(4)}
                >
                  <h3 className='stepper-title'>Availability</h3>
                </div>
              </div>

              <Formik
                validationSchema={currentSchema}
                initialValues={product}
                onSubmit={(values, actions) => {
                  //nothing to do
                }}
              >
                {(props) => (
                  <Form
                    className='mx-auto pt-4 pb-10'
                    style={{
                      minWidth: '90%',
                    }}
                    id='kt_create_account_form'
                  >
                    <div className='current' data-kt-stepper-element='content'>
                      <ProductInformationStep1 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <ProductCardAttributesStep2 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <ProductImagesStep3 />
                    </div>

                    <div data-kt-stepper-element='content'>
                      {props.values.id && (
                        <ProductAvailabilityStep4 product={props.values} />
                      )}
                    </div>

                    <div className='d-flex flex-stack pt-15'>
                      <div className='mr-2'>
                        <button
                          onClick={() => prevStep(props.values)}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <i className='fas fa-arrow-left fs-5 me-3'></i>
                          <span>Back</span>
                        </button>
                      </div>

                      <div>
                        {!lastStep && (
                          <button
                            className='btn btn-lg btn-light-primary m-2'
                            type='submit'
                            name='continue'
                            onClick={() => {
                              handleSubmitClick('continue', props);
                            }}
                          >
                            <span className='indicator-label'>
                              Save and Continue
                            </span>
                          </button>
                        )}
                        <button
                          className='btn btn-lg btn-primary'
                          type='submit'
                          name='close'
                          onClick={() => {
                            handleSubmitClick('close', props);
                          }}
                        >
                          <span className='indicator-label'>
                            Save and Close
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
