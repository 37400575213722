import { loadingSlice } from './loadingSlice';

const { actions } = loadingSlice;

export const showLoading = (url: string) => (dispatch: any) => {
  dispatch(actions.startCall({ url }));
};

export const hiddeLoading = (url: string) => (dispatch: any) => {
  dispatch(actions.endCall({ url }));
};
