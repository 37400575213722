import { useFormikContext } from 'formik';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect, { SingleValue } from 'react-select';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../redux';
import { UserGroupRelationModel } from '../../auth/models/UserGroupRelationModel';
import { entityServices } from '../../users/services/EntityServices';
import kitServices from '../services/KitsServices';

export interface ValueLabelPair {
  value: number;
  label: string;
}
const keyLabel = 'cascadeselects';
type Props = {
  kitId: number;
};
export function KitCascadeEntitySelection({ kitId }: Props) {
  const userGroupRelations = useSelector<RootState, UserGroupRelationModel>(
    ({ auth }: any) => auth.userGroupRelation,
    shallowEqual
  );
  const [accounts, setAccounts] = useState<ValueLabelPair[]>([]);
  const [clients, setClients] = useState<ValueLabelPair[]>([]);
  const [franchises, setFranchises] = useState<ValueLabelPair[]>([]);
  const [locations, setLocations] = useState<ValueLabelPair[]>([]);

  const { setFieldValue } = useFormikContext();

  //Clear refs
  const clientsRef = useRef<null | Select>(null);
  const franchiseRef = useRef<null | Select>(null);
  const locationsRef = useRef<null | Select>(null);

  const [d1, setD1] = useState(true);
  const [d2, setD2] = useState(true);
  const [d3, setD3] = useState(true);

  const availabilitiesInfo = useCallback(() => {
    kitServices.getAvailabilitiesInfo().then((response) => {
      const body = response.data;
      const itemsAccounts: ValueLabelPair[] = body.accounts.map((x) => {
        return { value: x.id, label: x.name };
      });
      const itemsClients: ValueLabelPair[] = body.clients.map((x) => {
        return { value: x.id, label: x.name };
      });
      const itemsFranchises: ValueLabelPair[] = body.franchises.map((x) => {
        return { value: x.id, label: x.name };
      });
      const itemsLocations: ValueLabelPair[] = body.locations.map((x) => {
        return { value: x.id, label: x.name };
      });
      setAccounts(itemsAccounts);
      setClients(itemsClients);
      setFranchises(itemsFranchises);
      setLocations(itemsLocations);
      // Initial Values
      if (userGroupRelations.accountId) {
        setFieldValue(`${keyLabel}.accountId`, userGroupRelations.accountId);
        setFieldValue(
          `${keyLabel}.accountName`,
          itemsAccounts.find((x) => x.value === userGroupRelations.accountId)
            ?.label
        );
      }
      if (userGroupRelations.clientId) {
        setFieldValue(`${keyLabel}.clientId`, userGroupRelations.clientId);
        setFieldValue(
          `${keyLabel}.clientName`,
          itemsClients.find((x) => x.value === userGroupRelations.clientId)
            ?.label
        );
      }
      if (userGroupRelations.franchiseId) {
        setFieldValue(
          `${keyLabel}.franchiseId`,
          userGroupRelations.franchiseId
        );
        setFieldValue(
          `${keyLabel}.franchiseName`,
          itemsFranchises.find(
            (x) => x.value === userGroupRelations.franchiseId
          )?.label
        );
      }
      if (userGroupRelations.locationId) {
        setFieldValue(`${keyLabel}.locationId`, userGroupRelations.locationId);
        setFieldValue(
          `${keyLabel}.locationName`,
          itemsLocations.find((x) => x.value === userGroupRelations.locationId)
            ?.label
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    availabilitiesInfo();
  }, [availabilitiesInfo]);
  useEffect(() => {
    if (userGroupRelations.accountId) {
      clientList(userGroupRelations.accountId);
    }
    if (userGroupRelations.clientId) {
      franchiseList(userGroupRelations.clientId);
    }
    if (userGroupRelations.franchiseId) {
      locationsList(userGroupRelations.franchiseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const clientList = useCallback((accountId: number) => {
    entityServices.getClients(accountId).then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setClients(items);
      setD1(false);
    });
  }, []);
  const franchiseList = useCallback((clientId: number) => {
    entityServices.getFranchises(clientId).then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setFranchises(items);
      setD2(false);
    });
  }, []);
  const locationsList = useCallback((franchiseId: number) => {
    entityServices.getLocations(franchiseId).then((values) => {
      const body = values.data;
      const items: ValueLabelPair[] =
        body.length > 0
          ? body.map((x) => {
              return { value: x.id, label: x.name };
            })
          : [];
      setLocations(items);
      setD3(false);
    });
  }, []);
  const handleAccountChange = (option: SingleValue<ValueLabelPair>) => {
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.accountId`, selectValue);
    setFieldValue(`${keyLabel}.accountName`, selectLabel);
    if (selectValue) clientList(selectValue);
    // Clear Refs 2 .. N
    clientsRef.current?.clearValue();
    franchiseRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD1(true);
    setD2(true);
    setD3(true);
  };
  const handleClientChange = (option: any) => {
    // Is Clerable case
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.clientId`, selectValue);
    setFieldValue(`${keyLabel}.clientName`, selectLabel);
    if (selectValue) franchiseList(selectValue);
    // Clear Refs 3 .. N
    franchiseRef.current?.clearValue();
    locationsRef.current?.clearValue();
    setD2(true);
    setD3(true);
  };
  const handleFranchiseChange = (option: any) => {
    // Is Clerable case
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.franchiseId`, selectValue);
    setFieldValue(`${keyLabel}.franchiseName`, selectLabel);
    if (selectValue) locationsList(selectValue);
    // Clear Refs 4 .. N
    locationsRef.current?.clearValue();
    setD3(true);
  };
  const handleLocationChange = (option: any) => {
    // Is Clerable case
    const selectValue =
      option && option.hasOwnProperty('value') ? option.value : null;
    const selectLabel =
      option && option.hasOwnProperty('label') ? option.label : '';
    setFieldValue(`${keyLabel}.locationId`, selectValue);
    setFieldValue(`${keyLabel}.locationName`, selectLabel);
  };
  return (
    <Fragment>
      <div className='row'>
        <div className='col-3'>
          <label className='form-label'>Account</label>
          <ReactSelect
            placeholder='All'
            defaultValue={[
              accounts[
                accounts.findIndex(
                  (x) => x.value === userGroupRelations.accountId
                )
              ],
            ]}
            value={
              accounts[
                accounts.findIndex(
                  (x) => x.value === userGroupRelations.accountId
                )
              ]
            }
            isClearable={!userGroupRelations.accountId ? true : false}
            isDisabled={userGroupRelations.accountId ? true : false}
            name={`${keyLabel}.accountId`}
            id={`${keyLabel}.accountId`}
            key={`${keyLabel}.accountId`}
            options={accounts}
            onChange={handleAccountChange}
          />
        </div>
        <div className='col-3'>
          <label className='form-label'>Client</label>
          <ReactSelect
            placeholder='All'
            isClearable={!userGroupRelations.clientId ? true : false}
            key={`${keyLabel}.clientId`}
            id={`${keyLabel}.clientId`}
            options={clients}
            name={`${keyLabel}.clientId`}
            ref={clientsRef}
            defaultValue={[
              clients[
                clients.findIndex(
                  (x) => x.value === userGroupRelations.clientId
                )
              ],
            ]}
            value={
              clients[
                clients.findIndex(
                  (x) => x.value === userGroupRelations.clientId
                )
              ]
            }
            isDisabled={d1 || userGroupRelations.clientId ? true : false}
            onChange={handleClientChange}
          />
        </div>
        <div className='col-3'>
          <label className='form-label'>Franchise</label>
          <ReactSelect
            placeholder='All'
            isClearable={!userGroupRelations.franchiseId ? true : false}
            id={`${keyLabel}.franchiseId`}
            key={`${keyLabel}.franchiseId`}
            options={franchises}
            name={`${keyLabel}.franchiseId`}
            ref={franchiseRef}
            defaultValue={[
              franchises[
                franchises.findIndex(
                  (x) => x.value === userGroupRelations.franchiseId
                )
              ],
            ]}
            value={
              franchises[
                franchises.findIndex(
                  (x) => x.value === userGroupRelations.franchiseId
                )
              ]
            }
            isDisabled={d2 || userGroupRelations.franchiseId ? true : false}
            onChange={handleFranchiseChange}
          />
        </div>
        <div className='col-3'>
          <label className='form-label'>Location</label>
          <ReactSelect
            placeholder='All'
            isClearable={!userGroupRelations.locationId ? true : false}
            id={`${keyLabel}.locationId`}
            name={`${keyLabel}.locationId`}
            key={`${keyLabel}.locationId`}
            options={locations}
            ref={locationsRef}
            defaultValue={[
              locations[
                locations.findIndex(
                  (x) => x.value === userGroupRelations.locationId
                )
              ],
            ]}
            value={
              locations[
                locations.findIndex(
                  (x) => x.value === userGroupRelations.locationId
                )
              ]
            }
            isDisabled={d3 || userGroupRelations.locationId ? true : false}
            onChange={handleLocationChange}
          />
        </div>
      </div>
    </Fragment>
  );
}
