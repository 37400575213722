import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../redux';
import { GroupModel } from '../../users/models/GroupModel';
import { OrderViewModel } from '../../orders/models/OrderViewModel';
import orderServices from '../../orders/services/OrdersServices';
import moment from 'moment';
import { StatusBadge } from '../../../components/StatusBadge';
import { OriginBadge } from '../../../components/OriginBadge';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { CancelOrder } from '../../orders/components/CancelOrder';
import { OrderStatusLine } from '../../orders/components/OrderStatusLine';
import { ProcessOrderModal } from './ProcessOrderModal';
import { OrderDetailShippingUnit } from './OrderDetailShippingUnit';
import { PackOrderModal } from './PackOrderModal';
import { formattedAdddress } from '../../orders/components/PlaceOrderResume';
import { UpdateTrakingNbrModal } from './UpdateTrakingNbrModal';
import { UpdateTrakingNbrForm } from './UpdateTrakingNbrForm';
import { OrderViewDetailModel } from '../../orders/models/OrderViewDetailModel';
import { ShippedOrderModal } from './ShippedOrderModal';
import { ReturnOrderModal } from '../../orders/components/ReturnOrderModal';
import { makePikingRequest, PACKED, PIKING } from '../helpers/detailsHelpers';
import { OrderviewDetailShippingUnitModel } from '../../orders/models/OrderViewDetailShippingUnitModel';
import { useDebouncedCallback } from 'use-debounce/lib';

interface Params {
  orderId: string;
}
interface OrderItem {
  number: string;
  checked?: boolean;
}
export function FulfillmentOrderView() {
  const intl = useIntl();
  const group = useSelector<RootState, GroupModel>(
    ({ auth }: any) => auth.group,
    shallowEqual
  );

  const { orderId } = useParams<Params>();
  const [order, setOrder] = useState<OrderViewModel | null>(null);
  const [orderItems, setOrderITems] = useState<OrderItem[]>([]);

  useEffect(() => {
    if (!order) {
      refetch();
    } else {
      let items: OrderItem[] = [];
      order.orderDetails.forEach((detail) => {
        if (detail.shippingUnits.length > 0) {
          detail.shippingUnits.forEach((unit) => {
            items.push({ number: unit.startingCardNumber, checked: false });
          });
        } else {
          items.push({ number: detail.productCode, checked: false });
        }
      });

      setOrderITems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, order]);

  const refetch = () => {
    orderServices.getOrder(Number(orderId)).then((res) => {
      setOrder(res.data);
    });
  };
  // Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };
  const handleShowCancelModal = () => {
    setShowCancelModal(true);
  };
  // Cancel Services
  const cancel = (values: any) => {
    handleCloseCancelModal();
    orderServices.cancelOrder(Number(orderId), values).then((newOrder) => {
      toast.success('Successfully canceled');
      goBack();
    });
  };
  // Return Modal
  const [showReturnModal, setShowReturnModal] = useState(false);
  const handleCloseReturnModal = () => {
    setShowReturnModal(false);
  };
  const handleShowReturnModal = () => {
    setShowReturnModal(true);
  };
  const returnOrder = (values: any) => {
    handleCloseReturnModal();
    orderServices.returnOrder(Number(orderId), values).then((newOrder) => {
      toast.success('Successfully returned');
      goBack();
    });
  };
  // Process Modal
  const [showPickingModal, setShowPickingModal] = useState(false);
  const handleClosePickingModal = () => {
    setShowPickingModal(false);
  };
  // Packed Modal
  const [showPackModal, setShowPackModal] = useState(false);
  const handleClosePackModal = () => {
    setShowPackModal(false);
  };
  // Shipped Modal
  const [showShippedModal, setShowShippedModal] = useState(false);
  const handleCloseShippedModal = () => {
    setShowShippedModal(false);
  };
  // Traking Form
  const [showTrakingForm, setShowTrakingForm] = useState(false);
  const handleCloseTrakingModal = () => {
    setShowTrakingForm(false);
  };
  //  Confirmation Modal
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [trakingNumber, setTrakingNumber] = useState<string>('');
  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false);
    setShowTrakingForm(false);
    setTrakingNumber('');
  };
  const handleOpenConfirmationModal = (trackingNumber: string) => {
    if (order && trackingNumber !== order.shippingTrackingNbr) {
      setConfirmationModal(true);
      setTrakingNumber(trackingNumber);
    } else {
      setShowTrakingForm(false);
      setTrakingNumber('');
    }
  };
  // Process Service
  const process = (id: number) => {
    handleClosePickingModal();
    orderServices.processOrder(id).then((newOrder) => {
      if (newOrder.data.status === order?.status) {
        toast.warning('The order cannot be picked');
      } else {
        toast.success('Picked successfully');
      }

      setOrder(newOrder.data);
    });
  };
  // Pack Order
  const packOrder = (
    id: number,
    trackingNumber: string,
    currentStatus: string
  ) => {
    setShowPackModal(false);
    const processOrder = makePikingRequest(currentStatus, PACKED);
    if (!processOrder) {
      orderServices.packOrder(id, trackingNumber).then((newOrder) => {
        toast.success('Packed successfully');
        setOrder(newOrder.data);
      });
    } else {
      orderServices.processOrder(id).then((newOrder) => {
        if (newOrder.data.status === PIKING) {
          orderServices.packOrder(id, trackingNumber).then((newOrder) => {
            toast.success('Packed successfully');
            setOrder(newOrder.data);
          });
        } else {
          toast.warning(
            `The order cannot be Packed . Now the current status is ${newOrder.data.status}`
          );
          setOrder(newOrder.data);
        }
      });
    }
  };
  // Pack Order
  const shipOrder = (
    id: number,
    trackingNumber: string,
    currentStatus: string
  ) => {
    setShowShippedModal(false);
    const processOrder = makePikingRequest(currentStatus, PACKED);
    if (!processOrder) {
      orderServices.shipOrder(id, trackingNumber).then((newOrder) => {
        toast.success('Shipped successfully');
        setOrder(newOrder.data);
      });
    } else {
      orderServices.processOrder(id).then((newOrder) => {
        if (newOrder.data.status === PIKING) {
          orderServices.shipOrder(id, trackingNumber).then((newOrder) => {
            toast.success('Shipped successfully');
            setOrder(newOrder.data);
          });
        } else {
          toast.warning(
            `The order cannot be Shipped. Now the current status is ${newOrder.data.status}`
          );
          setOrder(newOrder.data);
        }
      });
    }
  };
  // Update  Tracking Number
  const updateTrackingNumber = (id: number, trackingNumber: string) => {
    setConfirmationModal(false);
    setShowTrakingForm(false);
    orderServices.updateTrackingNumber(id, trackingNumber).then((newOrder) => {
      toast.success('Tracking number successfully updated');
      setOrder(newOrder.data);
    });
  };

  const history = useHistory();
  const goBack = () => {
    history.push('/fulfillments');
  };

  const isOrderShipped = () => {
    if (order?.status === 'Shipped') {
      return true;
    }
    return false;
  };

  const handleCheckShippingUnit = (
    shippingUnit: OrderviewDetailShippingUnitModel
  ) => {
    const units = orderItems;
    units.map((element) => {
      if (element.number === shippingUnit.startingCardNumber)
        element.checked = shippingUnit.checked;
      return element;
    });
    setOrderITems([...units]);
  };

  const handleCheckOrderDetail = (detail: OrderViewDetailModel) => {
    const units = orderItems;
    units.map((element) => {
      if (element.number === detail.productCode)
        element.checked = detail.checked;
      return element;
    });
    setOrderITems([...units]);
  };

  const [allShippingUnitsChecked, setAllShippingUnitsChecked] =
    useState<boolean>(false);
  const validateAllShippingUnitsChecked = () => {
    let allChecked = true;

    orderItems.forEach((element) => {
      if (!element.checked) {
        allChecked = false;
        return;
      }
    });
    setAllShippingUnitsChecked(allChecked);
    return allChecked;
  };

  useEffect(() => {
    if (orderItems.length > 0) {
      if (validateAllShippingUnitsChecked()) {
        setShowPackModal(true);
      }
    } else {
      setAllShippingUnitsChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItems]);
  const columns: TableColumn<OrderViewDetailModel>[] = useMemo(
    () => [
      {
        name: 'Qty.',
        selector: (row) => row.quantity,
        wrap: true,
        width: '130px',
        sortable: false,
        right: true,
      },
      {
        name: 'CODE',
        selector: (row) => row.productCode,
        width: '150px',
        sortable: false,
        wrap: true,
        right: true,
      },
      {
        name: 'PRODUCTS ORDERED',
        cell: (row) => {
          return (
            <>
              <label>{row.productDescription}</label>
              {row.type === 'Kit' && (
                <>
                  <label className='mx-2'>
                    (Kit: {row.kitDescription} - {row.kitCode})
                  </label>
                </>
              )}
            </>
          );
        },
        minWidth: '200px',
        wrap: true,
        sortable: false,
        center: true,
      },
      {
        name: 'PICKING LIST',
        cell: (row) => {
          return (
            <Fragment>
              {row.productType !== 'Card' ? (
                <>
                  {order?.status === 'Picking' && (
                    <table style={{ width: '100%' }}>
                      <tbody key={row.productCode}>
                        <tr>
                          <td className='fw-bolder w-100'>
                            <label className='form-check form-check-custom form-check-solid '>
                              <input
                                className='form-check-input me-2'
                                type='checkbox'
                                name={`${row.productCode}.check`}
                                id={`item-nocard-${row.productCode}-check-input`}
                                onChange={(e) => {
                                  row.checked = e.target.checked;
                                  handleCheckOrderDetail(row);
                                }}
                              />
                              {/* <span>{row.productCode}</span> */}
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              ) : (
                <OrderDetailShippingUnit
                  shippingUnits={row.shippingUnits}
                  shippingUnitSize={row.shippingUnitSize}
                  handleCheckShippingUnit={handleCheckShippingUnit}
                  status={order ? order.status : ''}
                />
              )}
            </Fragment>
          );
        },
        wrap: true,
        minWidth: '350px',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.status, orderItems]
  );
  const customStyles = {
    rows: {
      style: {
        minHeight: '68px',
      },
    },
    headCells: {
      style: {
        fontSize: '16px',
        color: '#a1a5b7',

        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#a1a5b7',
      },
    },
    cells: {
      style: {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: '#a1a5b7',

        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#a1a5b7',

        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#a1a5b7',

        fontSize: '16px',
      },
    },
    pagination: {
      style: {
        justifyContent: 'flex-start',
      },
    },
  };

  //Auto check picking list
  const filterInput = useRef<any>(null);
  setTimeout(() => {
    if (
      filterInput !== null &&
      filterInput.current &&
      filterInput.current.focus
    ) {
      filterInput.current.focus();
    }
  }, 200);
  const searchScanItem = (inputEvent: any) => {
    const inputValue = inputEvent.target.value;
    // Cards
    const checkInputCard = document.getElementById(
      `item-card-${inputValue}-check-input`
    ) as any;
    if (checkInputCard) {
      if (!checkInputCard.checked) {
        toast.success(`Item ${inputValue} selected`);
        checkInputCard.click();
      } else {
        toast.info(`Item ${inputValue} is already selected`);
      }
    }

    // Products
    const checkInputProduct = document.getElementById(
      `item-nocard-${inputValue}-check-input`
    ) as any;
    if (!checkInputCard && checkInputProduct) {
      if (!checkInputProduct.checked) {
        toast.success(`Item ${inputValue} selected`);
        checkInputProduct.click();
      } else {
        toast.info(`Item ${inputValue} is already selected`);
      }
    }

    if (!checkInputCard && !checkInputProduct) {
      toast.warning(`Item '${inputValue}' not found`);
    }

    if (filterInput !== null && filterInput.current) {
      filterInput.current.value = '';
      filterInput?.current?.focus();
    }
  };

  const searchScanItemDebounce = useDebouncedCallback(searchScanItem, 1000);
  const onPrint = () => {
    const ids = [Number(orderId)];
    orderServices.printOrders(ids, false, false, {}).then((response) => {
      const content = response.headers['content-type'];
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: content })
      );
      const ordersPrintWindow = window.open(url);
      // add auto print script
      var scriptElm = document.createElement('script');
      var inlineCode = document.createTextNode('window.print()');
      scriptElm.appendChild(inlineCode);
      ordersPrintWindow?.document.head.appendChild(scriptElm);
    });
  };
  // Add Skeleton
  if (!order) return null;
  const isCancellable =
    order?.status !== 'Shipped' &&
    order?.status !== 'BackOrdered' &&
    order?.status !== 'Returned' &&
    order?.status !== 'Cancelled';
  return (
    <Fragment>
      {group.orderPermission?.update && showCancelModal && (
        <CancelOrder
          show={showCancelModal}
          cancel={cancel}
          order={order}
          onHide={handleCloseCancelModal}
        />
      )}

      {group.orderPermission?.update && showPickingModal && (
        <ProcessOrderModal
          show={showPickingModal}
          process={process}
          order={order}
          onHide={handleClosePickingModal}
        />
      )}
      {group.orderPermission?.update && showReturnModal && (
        <ReturnOrderModal
          show={showReturnModal}
          returnOrder={returnOrder}
          order={order}
          onHide={handleCloseReturnModal}
        />
      )}
      {group.orderPermission?.update && showPackModal && (
        <PackOrderModal
          show={showPackModal}
          packOrder={packOrder}
          order={order}
          onHide={handleClosePackModal}
        />
      )}
      {group.orderPermission?.update && showConfirmationModal && (
        <UpdateTrakingNbrModal
          show={showConfirmationModal}
          id={order.id}
          newTrackingNumber={trakingNumber}
          oldTrackingNumber={order.shippingTrackingNbr}
          onHide={handleCloseConfirmationModal}
          updateTrackingNumber={updateTrackingNumber}
        />
      )}
      {group.orderPermission?.update && showShippedModal && (
        <ShippedOrderModal
          show={showShippedModal}
          order={order}
          shipOrder={shipOrder}
          onHide={handleCloseShippedModal}
        />
      )}

      <div className='card'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex align-items-center'>
                <div className='row'>
                  <div className='d-flex align-items-center'>
                    <div className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1'>
                      Order #{order?.id}
                    </div>
                    <div className='ms-4'>
                      {order?.origin && <OriginBadge origin={order.origin} />}
                    </div>
                    <div className='ms-4'>
                      {order?.status && <StatusBadge status={order.status} />}
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: 'auto' }}>
                  {goBack && (
                    <button
                      onClick={goBack}
                      className='btn btn-light btn-active-primary align-self-center'
                    >
                      <i className='fas fa-arrow-left'></i>
                      {intl.formatMessage({ id: 'ACTION.BACK' })}
                    </button>
                  )}

                  {group.orderPermission?.update && isCancellable && (
                    <button
                      className='m-2 me-0 btn btn-primary btn-active-primary align-self-center'
                      onClick={handleShowCancelModal}
                    >
                      Cancel Order
                    </button>
                  )}
                  {group.orderPermission?.update && isOrderShipped() && (
                    <button
                      className='ms-2 btn btn-primary btn-active-primary align-self-center'
                      onClick={handleShowReturnModal}
                    >
                      Return Order
                    </button>
                  )}
                  <button
                    type='button'
                    className='btn btn-light-primary ms-2'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='left'
                    onClick={() => onPrint()}
                  >
                    <i className='las la-print fs-2'></i>
                    Print
                  </button>
                </div>
              </div>

              <div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Date:
                      </div>
                      <div className='fw-bolder fs-6 text-dark col-9'>
                        {moment(order?.orderDate).format('MM-DD-YYYY')}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Client:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.client}
                      </div>
                    </div>

                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Franchise:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.franchise}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-3'>
                        Location:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-9'>
                        {' '}
                        {order?.location}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-4'>
                        Ship to:
                      </div>
                      <div className='fw-bolder fs-6 text-dark col-8'>
                        {order.location &&
                          formattedAdddress(order.orderLocation, false)}
                      </div>
                    </div>

                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-4'>
                        Shipping Method:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-8'>
                        {' '}
                        {order?.shippingMethod}
                      </div>
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-4 d-flex d-flex d-flex align-items-center'>
                        Tracking Number:
                      </div>
                      {order.shippingTrackingNbr && !showTrakingForm && (
                        <div
                          className='fw-bolder fs-6 text-dark  col-8 d-flex d-flex d-flex align-items-center'
                          onClick={() => setShowTrakingForm(true)}
                          style={{ cursor: 'pointer' }}
                        >
                          {order.shippingTrackingNbr}

                          <i className='las la-pen fs-2 mx-4 text-primary'></i>
                        </div>
                      )}
                      {order.shippingTrackingNbr && showTrakingForm && (
                        <UpdateTrakingNbrForm
                          show={showTrakingForm}
                          order={order}
                          onHide={handleCloseTrakingModal}
                          handleOpenConfirmationModal={
                            handleOpenConfirmationModal
                          }
                        />
                      )}
                    </div>
                    <div className='row my-2'>
                      <div className='fw-bolder fs-6 text-muted col-md-4'>
                        Created by:
                      </div>
                      <div className='fw-bolder fs-6 text-dark  col-8'>
                        {' '}
                        {order?.client}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {group.fulfillmentPermission && (
        <OrderStatusLine
          order={order}
          clickEvents={true}
          showPickingModal={setShowPickingModal}
          showPackModal={setShowPackModal}
          showShippedModal={setShowShippedModal}
          permissions={group.fulfillmentPermission}
          allShippingUnitsChecked={allShippingUnitsChecked}
        ></OrderStatusLine>
      )}

      <div className='card'>
        {order?.status === 'Picking' && (
          <div className='card-header'>
            <div className='d-flex align-items-center position-relative my-1 mt-5'>
              <div className='input-group mb-5'>
                <input
                  type='text'
                  className='form-control'
                  ref={filterInput}
                  placeholder='Search by S.U.'
                  aria-label='Search'
                  aria-describedby='basic-addon2'
                  onChange={searchScanItemDebounce}
                />
                <span className='input-group-text' id='basic-addon2'>
                  <i className='fas fa-barcode fs-4'></i>
                </span>
              </div>
            </div>
          </div>
        )}
        <div className='card-body'>
          <div className='px-6 px-lg-10 px-xxl-15'>
            {order?.orderDetails && (
              <DataTable
                columns={columns}
                data={order.orderDetails}
                customStyles={customStyles}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
